import { useCallback } from 'react';

import useLocalStorage from '../../../controllers/store/external-storage/use-local-storage';

import { inputPlaceholderMessages } from './messages';

const {
	searchAcrossProducts,
	submitExpenseQuestion,
	kpiAcronymSearch,
	mentionTeammatesSearch,
	searchTeammateName,
} = inputPlaceholderMessages;

// https://hello.atlassian.net/wiki/spaces/CentAI/pages/4405102308/DACI+Revolving+Rovo+Search+Ghost+Text
export const ghostTextInputPlaceholders = [
	// First session
	[
		searchAcrossProducts, // Ghost text 1
		submitExpenseQuestion, // Ghost text 2
		kpiAcronymSearch, // Ghost text 3
	],
	// Second session
	[
		submitExpenseQuestion, // Ghost text 1
		mentionTeammatesSearch, // Ghost text 2
		searchAcrossProducts, // Ghost text 3
	],
	// etc...
	[kpiAcronymSearch, searchAcrossProducts, submitExpenseQuestion],
	[searchTeammateName, submitExpenseQuestion, searchAcrossProducts],
	[mentionTeammatesSearch, searchAcrossProducts, submitExpenseQuestion],
];

export const getGhostTextInputPlaceholder = (session: number, index: number) => {
	// Reset index to 0 to guarantee that something will be displayed in the placeholder
	const sessionIndex = session % ghostTextInputPlaceholders.length;
	const ghostTextIndex = index % ghostTextInputPlaceholders[sessionIndex].length;
	return ghostTextInputPlaceholders[sessionIndex][ghostTextIndex];
};

export const useGhostTextSessionCount = (params: { initialValue: number; maxCount: number }) => {
	const [storedState, setState] = useLocalStorage();
	const localStorageValue: number | undefined = (storedState ?? {}).ghostTextSessionCount;
	const sessionCount = localStorageValue === undefined ? params.initialValue : localStorageValue;

	const incrementSession = useCallback(() => {
		setState({
			...storedState,
			ghostTextSessionCount: (sessionCount + 1) % params.maxCount,
		});
	}, [sessionCount, params.maxCount, setState, storedState]);

	return [sessionCount, { incrementSession }] as const;
};

import { fg } from '@atlaskit/platform-feature-flags';
import { createClient, JiraScope, type User, usersQuery } from '@atlassian/search-client';

import { type CloudConfig } from '../../../../../common/constants/filters/cloud-config/types';
import {
	ProductKeys,
	type ProductKeys1P,
	type ProductKeys3P,
} from '../../../../../common/constants/products';
import { getProducts3PFromConfigForFilter } from '../../../../../common/utils/filters';
import { createMultiSelectFilter } from '../../../../filters/multi-select-filter';

const searchAssignee = async (query: string, config: CloudConfig) => {
	const searchClient = createClient({
		cloudId: config.cloudId || '',
	});

	const searchAssigneesResponse = await searchClient.search({
		query,
		resultLimit: 5,
		scopes: [JiraScope.People],
		analytics: {
			identifier: 'search-page',
		},
	});
	const [searchAssigneeResults] = searchAssigneesResponse.response.rawData.scopes ?? [];

	const allAssignees = searchAssigneeResults.results.map((user) => ({
		picture: user.avatarUrl,
		name: user.name,
		id: `ari:cloud:identity::user/${user.id}`,
		accountId: user.id,
	})) satisfies User[];

	const userIds = new Set();

	const assigneeOptions = allAssignees
		.filter((user) => !userIds.has(user.accountId) && userIds.add(user.accountId)) // Remove duplicates
		.map((user) => ({
			trackingKey: user.id,
			value: user.id,
			label: user.name,
			avatarUrl: user.picture,
			queryParamValue: user.accountId,
		}));

	return assigneeOptions;
};

const productKeys1P: ProductKeys1P[] = [
	ProductKeys.Jira,
	ProductKeys.JiraProductDiscovery,
	ProductKeys.JiraServiceManagement,
];

const originalProducts3P: ProductKeys3P[] = [];

const products3PFromConfig: ProductKeys3P[] = getProducts3PFromConfigForFilter('assignee');

const getProducts3P = () => {
	return fg('rovo-full-page-search-3p-static-config') ? products3PFromConfig : originalProducts3P;
};

export const filter = createMultiSelectFilter({
	id: 'assignee',
	products: [...productKeys1P, ...getProducts3P()],
	alwaysVisible: false,
	universal: false,
	queryParams: {
		key: 'jAssignee',
		fetcher: async (queryParams, _) => {
			const initialUsersResponse = await usersQuery(queryParams);

			const allAssignees = initialUsersResponse.data?.users ?? ([] satisfies User[]);

			const userIds = new Set();

			const assigneeOptions = allAssignees
				.filter((user) => !userIds.has(user.accountId) && userIds.add(user.accountId)) // Remove duplicates
				.map((user) => ({
					trackingKey: user.id,
					value: user.id,
					label: user.name,
					avatarUrl: user.picture,
					queryParamValue: user.accountId,
				}));
			return assigneeOptions;
		},
	},
	initialOptions: {
		fetcher: async (config) => {
			return searchAssignee('', config);
		},
	},
	lookup: {
		fetcher: async (query, config) => {
			return searchAssignee(query, config);
		},
	},
});

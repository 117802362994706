import { fg } from '@atlaskit/platform-feature-flags';

import { ThirdPartyConfigs } from '../../../common/constants/3p-product-configs';
import { type ProductKeys } from '../../../common/constants/products';
/**
 * Utility to get the accessible products based on the lifecycle of fetching
 * the configuration of what a user is authorised to use.
 *
 * NOTE: The `products` parameter is deprecated as we are removing it as a
 * prop from the root <SearchPage/>
 *
 * @param supportedProducts Record of products supported by FPS
 * @param products Overrides the list of products a user can access
 * @param accessibleProducts The list of products a user is authorized to use. Initially
 * undefined while the user configuration is being retrieved.
 */
export const getAccessibleProducts = ({
	supportedProducts,
	products,
	accessibleProducts,
}: {
	supportedProducts: ProductKeys[];
	products?: ProductKeys[];
	accessibleProducts?: ProductKeys[];
}): ProductKeys[] => {
	if (!accessibleProducts) {
		return [];
	}

	const featureGatedNotEnabledProducts1P = [
		[fg('enable_rovo_search_bitbucket'), 'bitbucket'] as const,
		[fg('enable_1p_search_mercury'), 'mercury'] as const,
		[fg('enable_1p_search_trello'), 'trello'] as const,
	].flatMap(([isProductEnabled, product]) => (isProductEnabled ? [] : product));

	const disabledProducts3PFromConfig: ProductKeys[] = [];
	ThirdPartyConfigs.forEach((config, productKey3P) => {
		if (config && !config.isEnabled()) {
			disabledProducts3PFromConfig.push(productKey3P);
		}
	});

	const featureGatedNotEnabledProducts3P = fg('rovo-full-page-search-3p-static-config')
		? disabledProducts3PFromConfig
		: [
				[fg('enable_3p_search_onedrive'), 'onedrive'] as const,
				[fg('enable_3p_search_dropbox'), 'dropbox'] as const,
			].flatMap(([isProductEnabled, product]) => (isProductEnabled ? [] : product));

	const featureGatedNotEnabledProducts = [
		...featureGatedNotEnabledProducts1P,
		...featureGatedNotEnabledProducts3P,
	];

	const accessibleAndSupportedProducts = accessibleProducts
		.filter(
			(productKey) =>
				!featureGatedNotEnabledProducts.find(
					(featureGatedProduct) => featureGatedProduct === productKey,
				),
		)
		.filter((productKey: ProductKeys) => supportedProducts.includes(productKey));

	if (products) {
		const allowedProducts = products.filter((product) =>
			accessibleAndSupportedProducts.includes(product),
		);

		return allowedProducts;
	} else {
		return accessibleAndSupportedProducts;
	}
};

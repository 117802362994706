import { type AggRequest, type AggResponse, fetchAgg } from '../../../common/utils/fetch-agg';
import { generateHashFromVariables } from '../../../utils/caching/common/key-generator';
import { inMemoryCache } from '../../../utils/caching/in-memory';

import {
	queryGenerator,
	resultsCountOperationName,
	resultsCountQueryGenerator,
	type ResultsCountQueryVariables,
	SEARCH_DIALOG_OPERATION_NAME,
	SEARCH_PAGE_OPERATION_NAME,
	type SearchPageQueryVariables,
} from './query-generator';
import { type SearchPageData } from './types';

export type SearchPageQueryProps = Pick<
	AggRequest<SearchPageQueryVariables>,
	'aggAbsoluteUrl' | 'variables'
> & {
	originalQuery?: string;
};

export type ResultsCountQueryProps = Pick<
	AggRequest<ResultsCountQueryVariables>,
	'aggAbsoluteUrl' | 'variables'
> & {
	originalQuery?: string;
};

type SearchResultsQueryProps = SearchPageQueryProps & {
	useCache: boolean;
	operationName: string;
};

export const cache = inMemoryCache<AggResponse<SearchPageData>>();

export const generateCacheKey = (
	variables: SearchPageQueryVariables | ResultsCountQueryVariables,
	originalQuery?: string,
) => {
	/**
	 * Results once fetched will be used for upto 15 minutes from an in memory cache.
	 * 1. Generate key based on query, entities, filters
	 * 2. Serve from cache if present without resetting the expiration, evict if results are expired
	 * 3. If not present or expired then invoke the api
	 * 4. If API is invoked then store response in the cache with expiration and return back the response
	 */
	const keyObj = {
		query: variables.query,
		entities: variables.entities,
		commonFilters: variables.commonFilters,
		confluenceFilters: variables.confluenceFilters,
		jiraFilters: variables.jiraFilters,
		mercuryFilters: variables.mercuryFilters,
		thirdPartyFilters: variables.thirdPartyFilters,
		cloudIdARI: variables.cloudIdARI,
		sortField: variables.sort.at(0)?.field ?? '',
		sortOrder: variables.sort.at(0)?.order ?? '',
		after: variables.after,
		originalQuery,
	};

	return generateHashFromVariables(keyObj);
};

// Expire the cache if there are errors or edges are empty
const expireWhen = (result: AggResponse<SearchPageData>) => {
	const { data, errors } = result;
	if (!data || (errors && errors.length > 0)) {
		return true;
	}
	return data.search.results.edges.length === 0;
};

const searchQuery = ({
	variables,
	aggAbsoluteUrl,
	originalQuery,
	useCache,
	operationName,
}: SearchResultsQueryProps) => {
	// Default experience is search-page
	variables.experience = variables.experience || 'search-page';

	const graphQuery = queryGenerator(operationName);

	if (useCache) {
		return cache.inMemoryDecorator(
			generateCacheKey(variables, originalQuery),
			() =>
				fetchAgg<SearchPageQueryVariables, SearchPageData>({
					variables,
					aggAbsoluteUrl,
					graphQuery,
					operationName,
				}),
			expireWhen,
		);
	} else {
		return fetchAgg<SearchPageQueryVariables, SearchPageData>({
			variables,
			aggAbsoluteUrl,
			graphQuery,
			operationName,
		});
	}
};

export const searchPageQuery = ({
	variables,
	aggAbsoluteUrl,
	originalQuery,
}: SearchPageQueryProps) => {
	return searchQuery({
		variables,
		aggAbsoluteUrl,
		originalQuery,
		useCache: true,
		operationName: SEARCH_PAGE_OPERATION_NAME,
	});
};

export const searchDialogQuery = ({
	variables,
	aggAbsoluteUrl,
	originalQuery,
}: SearchPageQueryProps) => {
	return searchQuery({
		variables,
		aggAbsoluteUrl,
		originalQuery,
		useCache: false, // Bypassing cache to match previous Quick Find behavior
		operationName: SEARCH_DIALOG_OPERATION_NAME,
	});
};

export const resultsCountQuery = ({
	variables,
	aggAbsoluteUrl,
	originalQuery,
}: ResultsCountQueryProps) => {
	const graphQuery = resultsCountQueryGenerator();

	return cache.inMemoryDecorator('count' + generateCacheKey(variables, originalQuery), () =>
		fetchAgg<ResultsCountQueryVariables, SearchPageData>({
			variables,
			aggAbsoluteUrl,
			graphQuery,
			operationName: resultsCountOperationName,
		}),
	);
};

import { type MessageDescriptor } from 'react-intl-next';

import GlobeIcon from '@atlaskit/icon/core/globe';
import GoalIcon from '@atlaskit/icon/core/goal';
import HashtagIcon from '@atlaskit/icon/core/hashtag';
import ProjectIcon from '@atlaskit/icon/core/project';

import { type RovoLogo, RovoLogoFromLogo } from './icons';
import { messages } from './messages';

export interface NounConfig {
	displayName: MessageDescriptor;
	displayNamePlural: MessageDescriptor;
	Icon: RovoLogo;
}

export const NounKeys = {
	project: 'project',
	goal: 'goal',
	tag: 'tag',
	AtlassianWebCrawler: 'atlassian-web-crawler',
};

export type NounKeys = (typeof NounKeys)[keyof typeof NounKeys];

export const isNounKey = (key: string): key is NounKeys => {
	return Object.values(NounKeys).includes(key);
};

export const nouns: Record<string, NounConfig> = {
	[NounKeys.project]: {
		displayName: messages.project,
		displayNamePlural: messages.projects,
		Icon: RovoLogoFromLogo(ProjectIcon, 'Project'),
	},
	[NounKeys.goal]: {
		displayName: messages.goal,
		displayNamePlural: messages.goals,
		Icon: RovoLogoFromLogo(GoalIcon, 'Goal'),
	},
	[NounKeys.tag]: {
		displayName: messages.tag,
		displayNamePlural: messages.tags,
		Icon: RovoLogoFromLogo(HashtagIcon, 'Tag'),
	},
	[NounKeys.AtlassianWebCrawler]: {
		displayName: messages.webPage,
		displayNamePlural: messages.webPages,
		Icon: RovoLogoFromLogo(GlobeIcon, 'Atlassian Web Crawler'),
	},
};

export const NOUNS_ORDER: NounKeys[] = [
	NounKeys.project,
	NounKeys.goal,
	NounKeys.tag,
	NounKeys.AtlassianWebCrawler, // Web Crawler is not a noun, we should improve this
];

import { type AggRequest, fetchAgg } from '../../../../common/utils/fetch-agg';

import { operationName, quickFindRecentActivitiesQuery } from './query';
import {
	type QuickFindRecentActivitiesQuery,
	type QuickFindRecentActivitiesQueryVariables,
} from './types';

export type QuickFindRecentActivitiesQueryProps = Pick<
	AggRequest<QuickFindRecentActivitiesQueryVariables>,
	'aggAbsoluteUrl' | 'variables'
>;

export const recentActivitiesQuery = ({
	variables,
	aggAbsoluteUrl,
}: QuickFindRecentActivitiesQueryProps) => {
	return fetchAgg<QuickFindRecentActivitiesQueryVariables, QuickFindRecentActivitiesQuery>({
		variables,
		aggAbsoluteUrl,
		graphQuery: quickFindRecentActivitiesQuery,
		operationName,
	});
};

import { fg } from '@atlaskit/platform-feature-flags';
import {
	createClient,
	type JiraProject,
	jiraProjectsQuery,
	JiraScope,
} from '@atlassian/search-client';

import { type CloudConfig } from '../../../../../common/constants/filters/cloud-config/types';
import {
	ProductKeys,
	type ProductKeys1P,
	type ProductKeys3P,
} from '../../../../../common/constants/products';
import { getProducts3PFromConfigForFilter } from '../../../../../common/utils/filters';
import { createMultiSelectFilter } from '../../../../filters/multi-select-filter';

const projectIdToARI = (cloudId: string, projectId: string) =>
	`ari:cloud:jira:${cloudId}:project/${projectId}`;

const searchProject = async (query: string, config: CloudConfig) => {
	const searchClient = createClient({
		cloudId: config.cloudId || '',
	});

	const searchProjectsResponse = await searchClient.search({
		query,
		resultLimit: 5,
		scopes: [JiraScope.JiraProject],
		analytics: {
			identifier: 'search-page',
		},
	});

	const searchedProjects: JiraProject[] =
		searchProjectsResponse.response.rawData.scopes.length > 0
			? searchProjectsResponse.response.rawData.scopes[0].results.map((projectResult) => ({
					projectId: projectResult.id,
					name: projectResult.name,
					avatar: {
						small:
							'url' in projectResult.attributes.avatar
								? projectResult.attributes.avatar.url
								: undefined,
					},
				}))
			: [];

	const allProjects: JiraProject[] = searchedProjects;

	const projectOptions = allProjects.map((project) => ({
		trackingKey: projectIdToARI(config.cloudId || '', project.projectId),
		value: projectIdToARI(config.cloudId || '', project.projectId),
		label: project.name,
		avatarUrl: project.avatar?.small,
		queryParamValue: project.projectId,
	}));
	return projectOptions;
};

const productKeys1P: ProductKeys1P[] = [
	ProductKeys.Jira,
	ProductKeys.JiraProductDiscovery,
	ProductKeys.JiraServiceManagement,
];

const originalProducts3P: ProductKeys3P[] = [];

const products3PFromConfig: ProductKeys3P[] = getProducts3PFromConfigForFilter('project');

const getProducts3P = () => {
	return fg('rovo-full-page-search-3p-static-config') ? products3PFromConfig : originalProducts3P;
};

export const filter = createMultiSelectFilter({
	id: 'project',
	products: [...productKeys1P, ...getProducts3P()],
	alwaysVisible: true,
	universal: false,
	queryParams: {
		key: 'jProject',
		fetcher: async (queryParams, config) => {
			const initialProjectARIs = queryParams.map((projectId) =>
				projectIdToARI(config.cloudId || '', projectId),
			);
			const initialProjectsResponse = await jiraProjectsQuery(initialProjectARIs);

			const initialProjects: JiraProject[] = initialProjectsResponse.data?.jira.jiraProjects ?? [];

			const allProjects: JiraProject[] = initialProjects;
			const projectOptions = allProjects.map((project) => ({
				trackingKey: projectIdToARI(config.cloudId || '', project.projectId),
				value: projectIdToARI(config.cloudId || '', project.projectId),
				label: project.name,
				avatarUrl: project.avatar?.small,
				queryParamValue: project.projectId,
			}));
			return projectOptions;
		},
	},
	initialOptions: {
		fetcher: async (config) => {
			return searchProject('', config);
		},
	},
	lookup: {
		fetcher: async (query, config) => {
			return searchProject(query, config);
		},
	},
});

import React from 'react';

import { FormattedMessage, useIntl } from 'react-intl-next';

import AtlassianIntelligenceIcon from '@atlaskit/icon/core/atlassian-intelligence';
import { AtlassianIntelligenceDarkIcon } from '@atlaskit/legacy-custom-icons';
import Link from '@atlaskit/link';
import { Inline, Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { i18n } from './messages';

const StreamingSummaryFooterStyles = xcss({
	color: 'color.text.subtlest',
	font: 'font.body.small',
});

const ATLASSIAN_INTELLIGENCE_LEARN_MORE_LINK =
	'https://support.atlassian.com/organization-administration/docs/understand-atlassian-intelligence-features-in-products';

export const StreamingSummaryFooter = () => {
	const { formatMessage } = useIntl();

	return (
		<Stack space="space.200" xcss={StreamingSummaryFooterStyles}>
			<Inline spread="space-between">
				<Inline>
					<FormattedMessage
						{...i18n.footerQualityDescriptor}
						values={{
							learnMoreLink: (chunks: React.ReactNode[]) => (
								<Link
									href={ATLASSIAN_INTELLIGENCE_LEARN_MORE_LINK}
									target="_blank"
									testId="learn-why-link"
								>
									{chunks}
								</Link>
							),
						}}
					/>
				</Inline>
				<Inline space="space.050" alignBlock="center">
					{formatMessage(i18n.poweredBy)}
					<AtlassianIntelligenceIcon
						color={token('color.icon')}
						label={formatMessage(i18n.atlassianIntelligenceIconAlt)}
						LEGACY_fallbackIcon={AtlassianIntelligenceDarkIcon}
					/>
				</Inline>
			</Inline>
		</Stack>
	);
};

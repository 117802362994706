import type { SearchResult } from '../../../../common/types';

const SearchResultMercuryFocusArea = `
... on SearchResultMercuryFocusArea {
	lastModifiedDate
	focusArea {
		id
		name
		focusAreaType {
			id
			name
			hierarchyLevel
		}
		status {
			key
			displayName
		}
		health {
			key
			color
			displayName
		}
		targetDate {
			targetDate
			targetDateType
		}
		owner {
			accountId
			name
		}
	}
}
`;

export interface SearchResultMercuryFocusArea extends SearchResult {
	lastModifiedDate?: string;
	focusArea?: {
		id: string;
		name: string;
		focusAreaType: {
			id: string;
			name: string;
			hierarchyLevel: number;
		};
		status: {
			key: string;
			displayName: string;
		};
		health?: {
			key: string;
			color: string;
			displayName: string;
		};
		targetDate?: {
			targetDate: string;
			targetDateType: string;
		};
		owner?: {
			accountId: string;
			name: string;
		};
	};
	__typename: 'SearchResultMercuryFocusArea';
}

export type SearchMercuryPartial = SearchResultMercuryFocusArea;

export const isSearchResultMercuryFocusAreaPartial = (
	partial: SearchResult,
): partial is SearchResultMercuryFocusArea => {
	return partial?.id?.includes('mercury') && partial.__typename === 'SearchResultMercuryFocusArea';
};

export const isSearchMercuryPartial = (partial: SearchResult): partial is SearchMercuryPartial => {
	return isSearchResultMercuryFocusAreaPartial(partial);
};

export const getMercuryPartials = () => {
	return SearchResultMercuryFocusArea;
};

import React, { Fragment } from 'react';

import { Inline, xcss } from '@atlaskit/primitives';

import { ProductKeys, useProductConfigs } from '../../constants/products';
import { Spacer } from '../metadata-spacer';
import { Show } from '../width-observer';

/** A metadata part to render */
export type SearchMetadataPart = string | null | [string, React.ReactNode];

const socialMetadataStyles = xcss({
	alignItems: 'center',
	paddingInlineStart: 'space.050',
	paddingInlineEnd: 'space.050',
});

const renderParts = (part: SearchMetadataPart, spacer: boolean) => {
	const [key, value] = Array.isArray(part) ? part : [part, part];
	return (
		<Fragment key={key}>
			{value}
			{spacer && <Spacer />}
		</Fragment>
	);
};

const filterPart = (part: SearchMetadataPart): boolean => {
	if (Array.isArray(part)) {
		return !!part[1];
	}
	return !!part;
};

const renderSocialMetadata = (defaultSocialParts: SearchMetadataPart[]) => {
	const hasBreadcrumb =
		defaultSocialParts.filter((item) => Array.isArray(item) && item[1] === 'social-signal').length >
		0;
	const parts = hasBreadcrumb
		? defaultSocialParts.slice(0, -1).filter(filterPart)
		: defaultSocialParts.filter(filterPart);

	return (
		<>
			<Inline space="space.150" xcss={socialMetadataStyles}>
				<>{parts?.map((m) => renderParts(m, false))}</>
			</Inline>
			{hasBreadcrumb && defaultSocialParts[defaultSocialParts.length - 1]}
		</>
	);
};

export type SearchMetadataProps = {
	// TODO: This was previously ProductType
	// We need to align our ProductKeys types with
	// import type { ProductType } from '@atlassian/search-client';
	// For now, lets drop to string and re-cast
	product?: string;
	productSelected?: boolean;
	/** Nullish/empty string parts are filtered out. */
	parts?: Array<SearchMetadataPart>;
	socialParts?: Array<SearchMetadataPart>;
	displaySpacer?: boolean;
};

export const SearchMetadata = ({
	product,
	productSelected,
	parts: defaultParts = [],
	socialParts: defaultSocialParts = [],
	displaySpacer = true,
}: SearchMetadataProps) => {
	const products = useProductConfigs();
	// TODO: Shouldn't need to cast here, need to clean up the stack above this to not pass string around as product
	const productDisplayName =
		!product || productSelected ? '' : products[product as ProductKeys]?.displayName || '';
	const parts = defaultParts.filter(filterPart);
	if (defaultSocialParts.filter((item) => item !== null).length > 0) {
		parts.push(['social-metadata', renderSocialMetadata(defaultSocialParts)]);
	}

	return (
		<>
			{productDisplayName && (
				<Show target="layout" above="sm">
					{productDisplayName}
					<Spacer />
				</Show>
			)}
			<>{parts?.map((m, i) => renderParts(m, displaySpacer && i < parts.length - 1))}</>
		</>
	);
};

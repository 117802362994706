import { SearchConfluenceSubtype } from '../../../../common/types';
import { type AggResponse } from '../../../../common/utils/fetch-agg';

// Types for AGG graphQL queries are NOT autogenerated in CFE
// This file was manually created
export enum ActivityProduct {
	JIRA = 'jira',
	CONFLUENCE = 'confluence',
	ATLAS = 'townsquare',
	GRAPH = 'graph',
}

export enum VIEWED_EVENT {
	VIEWED = 'viewed',
}

export enum WORKED_EVENT {
	COMMENTED = 'commented',
	CREATED = 'created',
	EDITED = 'edited',
	PUBLISHED = 'published',
	UPDATED = 'updated',
}

export type ActivityEventType = VIEWED_EVENT | WORKED_EVENT;

export interface Ancestor {
	id: string;
	title: string | null;
}

export interface Link {
	base: string | null;
	webUi: string | null;
}

export interface JiraFieldNode {
	text: string | null;
}

export interface JiraFieldEdge {
	node: JiraFieldNode | null;
}
export interface QuickFindAllActivityDataEdgeObjectDataStatusCategoryNode {
	name: string;
	colorName: string;
}

export interface FieldsById {
	edges: JiraFieldEdge[] | null;
}

export interface Space {
	id: string | null;
	name: string | null;
}

export interface QuickFind1PEntity {
	title?: string | null;
	key?: string;
	name?: string;
	pageStatus?: string;
	blogStatus?: string;
	subtype?: SearchConfluenceSubtype;
	ancestors?: Ancestor[] | null;
	links?: Link | null;
	url?: string | null;
	webUrl?: string;
	iconData?: string | null;
	fieldsById?: FieldsById | null;
	space?: Space | null;
	statusCategory?: QuickFindAllActivityDataEdgeObjectDataStatusCategoryNode;
}

export interface ExternalDocument {
	displayName?: string;
	type?: ExternalDocumentType;
	url?: string | null;
	parent?: ExternalDocument;
}

enum ExternalDocumentCategory {
	DOCUMENT,
	PRESENTATION,
	SPREADSHEET,
}
interface ExternalDocumentType {
	category?: ExternalDocumentCategory;
}

export type ActivityEntity = QuickFind1PEntity | QuickFindGraphEntity;

export type QuickFindGraphEntity = ExternalDocument;

export interface ActivityObject<EntityType = ActivityEntity> {
	id: string;
	type: string;
	product: ActivityProduct;
	data: EntityType | null;
}

export interface ActivityEvent {
	eventType: ActivityEventType;
	timestamp: string;
}

export interface ActivityNode<EntityType = ActivityEntity> {
	object: ActivityObject<EntityType>;
	event: ActivityEvent;
}

export interface ActivityItemEdge {
	node: ActivityNode;
}

export interface ActivityConnection {
	edges: ActivityItemEdge[];
}

export interface QuickFindAllActivity {
	all: ActivityConnection;
}

export interface QuickFindMyActivity {
	myActivity: QuickFindAllActivity | null;
}

export interface QuickFindRecentActivitiesQuery {
	activity: QuickFindMyActivity | null;
}

export interface QuickFindRecentActivitiesQueryVariables {
	filteredProducts: string[];
	filteredObjectTypes: string[];
	itemsLimit: number;
	filteredRootContainerIds: string[];
}

export type QuickFindRecentActivitiesResponse = AggResponse<QuickFindRecentActivitiesQuery>;

export const isDocumentActivity = (
	activity: ActivityObject<QuickFindGraphEntity>,
): activity is ActivityObject<ExternalDocument> => {
	return activity.type === 'document';
};

export const isGraphActivity = (
	activity: ActivityObject<ActivityEntity>,
): activity is ActivityObject<QuickFindGraphEntity> => {
	return activity.product === 'graph';
};

export const is1PActivity = (
	activity: ActivityObject<ActivityEntity>,
): activity is ActivityObject<QuickFind1PEntity> => {
	return (
		activity.product === 'confluence' ||
		activity.product === 'jira' ||
		activity.product === 'townsquare'
	);
};

import React, { useState } from 'react';

import { useIntl } from 'react-intl-next';

import Button from '@atlaskit/button/new';
import { FlagsProvider } from '@atlaskit/flag';
import InfoIcon from '@atlaskit/icon/utility/migration/information--info';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import { Box, Stack, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { type NounKeys } from '../../constants/nouns';
import { type ProductKeys, useProductConfigs } from '../../constants/products';
import type { TriggerComponentProps } from '../../types';
import type { FilterOption } from '../filters/app-filter-sidebar/show-more-apps-popup';

import { messages } from './messages';
interface OAuthHandlersProps {
	product: ProductKeys | NounKeys | undefined;
	successFlagTitle: React.ReactNode;
	failFlagTitle: React.ReactNode;
	outboundAuthUrl: string;
	onCompletedAction?: () => void;
	onDismissAction?: () => void;
	isOnSearchDialog?: boolean;
	query?: string;
}

interface OAuthHandlerFunctions {
	onAuthorize: () => void;
	onDismiss: () => void;
}

export type UseOAuthHandlers = (props: OAuthHandlersProps) => OAuthHandlerFunctions;

export type UseIsUserOAuthed = (productKey: ProductKeys | NounKeys | undefined) =>
	| {
			userNeedsOAuth: undefined;
			outboundAuthUrl: undefined;
	  }
	| {
			userNeedsOAuth: boolean;
			outboundAuthUrl: string;
	  };

type OAuthWarningWrapperProps = {
	product: ProductKeys | NounKeys | undefined;
	isInline?: boolean;
	trigger: (props: TriggerComponentProps) => JSX.Element;
	isOnSearchDialog?: boolean;
	searchQuery?: string;
	useOAuthHandlers: UseOAuthHandlers;
	useIsUserOAuthed: UseIsUserOAuthed;
	option?: FilterOption;
};

const popupContentStyles = xcss({
	width: '20rem',
	padding: 'space.200',
	display: 'flex',
	flexDirection: 'column',
});

const buttonContainerStyles = xcss({
	display: 'flex',
	justifyContent: 'flex-end',
	flexDirection: 'row',
});
const buttonStyles = xcss({
	margin: 'space.100',
});

const infoIconStyles = xcss({
	marginLeft: 'space.050',
	cursor: 'pointer',
});
const popupTriggerStyles = xcss({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
});
const connectButtonStyles = xcss({
	marginLeft: 'auto',
	right: 'space.200',
});
const sidebarStyles = xcss({
	width: '100%',
	paddingBottom: 'space.025',
});
const connectTextStyles = xcss({
	color: 'color.link',
	font: 'font.body',
	fontWeight: 'font.weight.medium',

	':hover': {
		color: 'color.link.pressed',
		textDecoration: 'underline',
	},
});

export const OAuthWarningWrapperImpl = ({
	product,
	isInline,
	trigger,
	isOnSearchDialog,
	searchQuery,
	useOAuthHandlers,
	useIsUserOAuthed,
	option,
}: OAuthWarningWrapperProps) => {
	const products = useProductConfigs();
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const { formatMessage } = useIntl();
	const { userNeedsOAuth, outboundAuthUrl } = useIsUserOAuthed(product);

	const productString = (product && products[product as ProductKeys]?.displayName) ?? '';
	const { onAuthorize, onDismiss } = useOAuthHandlers({
		product,
		successFlagTitle: formatMessage(messages.authorizationSuccess, {
			product: productString,
		}),
		failFlagTitle: formatMessage(messages.authorizationFail, {
			product: productString,
		}),
		outboundAuthUrl: outboundAuthUrl || '',
		onCompletedAction: () => {
			setIsPopupOpen(false);
			option?.showFilter();
			option?.onSelect();
		},
		onDismissAction: () => {
			setIsPopupOpen(false);
		},
		isOnSearchDialog,
		query: searchQuery,
	});

	//This component is a functional wrapper that turns the trigger prop into a popup trigger.
	// if no warning is needed, we just render the trigger
	if (!product || !userNeedsOAuth || !outboundAuthUrl) {
		return trigger({ isDisabled: false });
	}

	const onOpenPopup = () => {
		setIsPopupOpen(!isPopupOpen);
	};

	const triggerComponent = (triggerProps: TriggerProps) => {
		const oAuthWarning = isInline ? (
			<Box xcss={infoIconStyles}>
				<InfoIcon
					LEGACY_margin={`${token('space.100')} 0 0 0`}
					label="oauth warning"
					LEGACY_size="small"
					color={token('color.icon.brand')}
				/>
			</Box>
		) : (
			<Stack xcss={connectButtonStyles}>
				<Box xcss={connectTextStyles}>{formatMessage(messages.connect)}</Box>
			</Stack>
		);

		return (
			<Box
				onClick={onOpenPopup}
				xcss={[popupTriggerStyles, !isInline && !isOnSearchDialog && sidebarStyles]}
			>
				{trigger({ oAuthWarning, isDisabled: true, triggerProps })}
			</Box>
		);
	};

	return (
		<Popup
			isOpen={isPopupOpen}
			onClose={() => setIsPopupOpen(false)}
			placement={isOnSearchDialog || isInline ? 'bottom-start' : 'left-start'}
			trigger={triggerComponent}
			shouldRenderToParent={true}
			content={() => (
				<Box xcss={popupContentStyles}>
					<Stack space="space.100">
						<Text>
							{product === 'figma'
								? formatMessage(messages.authorizeConnectThirdPartyProductAlternate, {
										product: productString,
										strong: (text: React.ReactNode) => <Text as="strong">{text}</Text>,
										br: <br />,
									})
								: formatMessage(messages.authorizeConnectThirdPartyProduct, {
										product: productString,
										strong: (text: React.ReactNode) => <Text as="strong">{text}</Text>,
										br: <br />,
									})}
						</Text>
					</Stack>
					<Stack xcss={buttonContainerStyles}>
						<Stack xcss={buttonStyles}>
							<Button onClick={onDismiss}>{formatMessage(messages.notNow)}</Button>
						</Stack>
						<Stack xcss={buttonStyles}>
							<Button onClick={onAuthorize} appearance="primary">
								{formatMessage(messages.connect)}
							</Button>
						</Stack>
					</Stack>
				</Box>
			)}
		/>
	);
};

export const OAuthWarningWrapper = (props: OAuthWarningWrapperProps) => {
	return props.isOnSearchDialog ? (
		<OAuthWarningWrapperImpl {...props} />
	) : (
		<FlagsProvider>
			<OAuthWarningWrapperImpl {...props} />
		</FlagsProvider>
	);
};

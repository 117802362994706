import React from 'react';

import Lozenge, { type ThemeAppearance } from '@atlaskit/lozenge';
import {
	JiraStatusCategoryColor,
	type JiraStatusCategoryColorType,
} from '@atlassian/search-client';

import { MetaDataItemSkeleton } from '../../search-result-skeleton';

const jiraIssueStatusToLozengeMap = {
	[JiraStatusCategoryColor.Red]: 'removed',
	[JiraStatusCategoryColor.Brown]: 'default',
	[JiraStatusCategoryColor.Green]: 'success',
	[JiraStatusCategoryColor.Yellow]: 'moved',
	[JiraStatusCategoryColor.BlueGray]: 'inprogress',
	[JiraStatusCategoryColor.MediumGray]: 'default',
} satisfies Record<JiraStatusCategoryColorType, ThemeAppearance>;

const STATUS_REGEX = /[\s-]+/g;
/**
 * Remove when visual refresh flag is removed
 */
const getAppearance = (name: string) => {
	name = name.replace(STATUS_REGEX, '').toLowerCase();
	switch (true) {
		case ['todo', 'unavailable', 'minor', 'notstarted'].includes(name):
			return 'default';

		case ['available', 'completed', 'done', 'approved', 'resolved', 'added', 'closed'].includes(
			name,
		):
			return 'success';

		case ['error', 'declined', 'deleted', 'failed', 'rejected'].includes(name):
			return 'removed';

		case ['inprogress', 'open', 'modified'].includes(name):
			return 'inprogress';
	}
	return 'default';
};

export const getStatus = (
	name?: string,
	color?: JiraStatusCategoryColorType,
	isLoading?: boolean,
	showVisualRefresh?: boolean,
) => {
	if (name) {
		if (isLoading) {
			return <MetaDataItemSkeleton />;
		}
		if (color && showVisualRefresh) {
			return (
				<Lozenge
					isBold
					appearance={jiraIssueStatusToLozengeMap[color]}
					testId="jira-status-lozenge-test-id"
				>
					{name}
				</Lozenge>
			);
		}

		const appearance = getAppearance(name);
		if (appearance) {
			return (
				<Lozenge appearance={appearance} testId="jira-status-lozenge-test-id">
					{name}
				</Lozenge>
			);
		}
	}
	if (isLoading) {
		return <MetaDataItemSkeleton />;
	}
	return null;
};

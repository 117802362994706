import React, { useEffect, useMemo, useState } from 'react';

import { useIntl } from 'react-intl-next';

import { useAutoSelectPrimaryProduct } from '../../bootstrap';
import { useApplicationMode, useBootstrap } from '../../index';
import { allFilters } from '../filters';
import { SearchStoreContainer } from '../index';

export const SearchStoreProvider = ({ children }: { children: React.ReactNode }) => {
	const [shouldAutoSelectPrimaryProduct, setShouldAutoSelectPrimaryProduct] = useState(true);
	const intl = useIntl();

	const [
		{
			queryParams,
			cloudId,
			aggAbsoluteUrl,
			initialSpaceARIs,
			user,
			products,
			allFilterExcludedProducts,
			primaryProduct,
		},
		{ setQueryParams },
	] = useBootstrap();

	const applicationMode = useApplicationMode();

	const primaryProductToAutoSelect = useAutoSelectPrimaryProduct();

	const config = useMemo(
		() => ({
			intl: intl,
			ari: '',
			cloudId: cloudId,
			userId: user?.id || '',
			initialSpaceARIs: initialSpaceARIs,
			aggAbsoluteUrl: aggAbsoluteUrl,
		}),
		[aggAbsoluteUrl, cloudId, initialSpaceARIs, intl, user?.id],
	);

	const queryParamsInUse = useMemo(() => {
		const { lastModified_lt, lastModified_gt, ...queryParamsInUse } = queryParams;
		return queryParamsInUse;
	}, [queryParams]);

	// primaryProductToAutoSelect will be set to the primary product if the user is in unified search
	// and no product is selected
	// This is to ensure that the primary product is selected by default for unified search
	useEffect(() => {
		if (products && products.length > 0) {
			if (shouldAutoSelectPrimaryProduct && primaryProductToAutoSelect) {
				setQueryParams({ ...queryParams, product: primaryProductToAutoSelect });
			}
			setShouldAutoSelectPrimaryProduct(false);
		}
	}, [
		applicationMode,
		primaryProduct,
		primaryProductToAutoSelect,
		products,
		queryParams,
		setQueryParams,
		shouldAutoSelectPrimaryProduct,
	]);

	return (
		<SearchStoreContainer
			queryParams={queryParamsInUse}
			allFilters={allFilters}
			config={config}
			setQueryParams={setQueryParams}
			availableProducts={products}
			allFilterExcludedProducts={allFilterExcludedProducts}
			applicationMode={applicationMode}
			primaryProduct={primaryProduct}
		>
			{children}
		</SearchStoreContainer>
	);
};

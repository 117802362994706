import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	authorizationFail: {
		id: 'oauth-banner.authorization-fail',
		defaultMessage: 'Failed to connect to {product}.',
		description: 'Message displayed when authorization fails.',
	},
	authorizationSuccess: {
		id: 'oauth-banner.authorization-success',
		defaultMessage: 'Account connected to {product}.',
		description: 'Message displayed when authorization is successful.',
	},
});

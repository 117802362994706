import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	authorizeConnectThirdPartyProduct: {
		id: 'sidebar-authorize_connect_third_party_product',
		defaultMessage: `Your admin has added <strong>{product}!</strong>{br}
			Connect your account to see missing results, including documents only you have access to.
		`,
		description: 'Message shown to prompt users to authorize third-party products',
	},
	authorizeConnectThirdPartyProductAlternate: {
		id: 'sidebar-authorize_connect_third_party_product_alternate',
		defaultMessage: `<strong>{product} is now available!</strong>{br}
			Connect your account to see missing results, including documents only you have access to.
		`,
		description:
			'Alternate message shown to prompt users to authorize third-party products like figma (not added by admin)',
	},
	dismiss: {
		id: 'sidebar-oauth-warning-dismiss',
		defaultMessage: 'Dismiss',
		description: 'Message for button to dismiss the oauth message',
	},
	authorize: {
		id: 'sidebar-oauth-warning-authorize',
		defaultMessage: 'Authorize',
		description: 'Message for button to authorize the third-party product',
	},
	authorizationFail: {
		id: 'sidebar-oauth-warning.authorization-fail',
		defaultMessage: 'Failed to connect to {product}.',
		description: 'Message displayed when authorization fails.',
	},
	authorizationSuccess: {
		id: 'sidebar-oauth-warning.authorization-success',
		defaultMessage: 'Successfully connected to {product}.',
		description: 'Message displayed when authorization is successful.',
	},
	notNow: {
		id: 'sidebar-oauth-warning.not-now',
		defaultMessage: 'Not now',
		description: 'Message for button to dismiss the oauth message',
	},
	connect: {
		id: 'sidebar-oauth-warning.connect',
		defaultMessage: 'Connect',
		description: 'Message for button to connect the oauth message',
	},
});

import type { AllFilterStates, AllFilterValues } from '../../../../common/constants/filters/types';
import {
	type ProductKeys,
	productKeySchema,
} from '../../../../common/constants/schemas/query-params';
import { type QueryParams } from '../../../../common/types';

const notEmpty = <TValue>(value: TValue | null | undefined): value is TValue => {
	return value !== null && value !== undefined;
};

export const FilterUtils = (
	staticFilters: Record<string, AllFilterValues> | AllFilterValues[],
	filterStates: Record<string, AllFilterStates | undefined> | AllFilterStates[],
) => {
	const staticFilterValues = Array.isArray(staticFilters)
		? staticFilters
		: Object.values(staticFilters);
	const staticFiltersRecord = Array.isArray(staticFilters)
		? Object.fromEntries(staticFilters.map((staticFilter) => [staticFilter.id, staticFilter]))
		: staticFilters;

	const filterStateValues = Array.isArray(filterStates)
		? filterStates
		: Object.values(filterStates).filter(notEmpty);
	const filterStatesRecord = Array.isArray(filterStates)
		? Object.fromEntries(filterStates.map((filterState) => [filterState.id, filterState]))
		: filterStates;

	return {
		staticFilters: staticFiltersRecord,
		staticFilterValues: staticFilterValues,
		filterStates: filterStatesRecord,
		filterStateValues: filterStateValues,
		getSelectedProducts: (queryParams: QueryParams) => {
			const queryParamKeys = Object.keys(queryParams);
			const productKey = productKeySchema.safeParse(queryParams.product);

			if (productKey.success) {
				return [productKey.data];
			}

			const allProductsWithSelections = staticFilterValues
				.filter((staticFilter) => !staticFilter.universal) // Ignore universal filters as they are relevant to every product
				.filter((staticFilter) => queryParamKeys.includes(staticFilter.queryParams.key))
				.map((staticFilter) => staticFilter.products)
				.flat();

			const selectedProducts = Array.from(new Set(allProductsWithSelections)); // might have to sort this based on some priority

			return selectedProducts;
		},
		isMissingProductSelection: (queryParams: QueryParams) => {
			const selectedProducts = FilterUtils(staticFilters, filterStates).getSelectedProducts(
				queryParams,
			);

			const productKey = productKeySchema.safeParse(queryParams.product).data;
			return selectedProducts.length > 0 && !productKey;
		},
		filtersByProduct: (products: ProductKeys[]) => {
			const staticFiltersByProduct = staticFilterValues.filter((staticFilterValue) => {
				if (!products.length) {
					return staticFilterValue.universal; // Only universal filters are relevant if no apps are selected
				}
				return staticFilterValue.products.some((product) => products.includes(product));
			});

			const staticFiltersByProductKeys = staticFiltersByProduct.map(
				(staticFilter) => staticFilter.id,
			);
			const filterStatesByProduct = filterStateValues.filter((filterState) =>
				staticFiltersByProductKeys.includes(filterState.id),
			);

			return FilterUtils(staticFiltersByProduct, filterStatesByProduct);
		},
		getFiltersWithSelection: () => {
			const filterIDsWithSelection = filterStateValues
				.filter((x) => x.hasSelection)
				.map((x) => x.id);
			return filterIDsWithSelection;
		},
	};
};

import React from 'react';

import AppsIcon from '@atlaskit/icon/core/apps';
import { Box, xcss } from '@atlaskit/primitives';
import { B400, N500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { type RovoLogoProps } from '../../constants/icons';
import { isNounKey, type NounKeys, nouns } from '../../constants/nouns';
import { type ProductKeys, useProductConfigs } from '../../constants/products';

export type ProductIconProps = RovoLogoProps & {
	product: ProductKeys | undefined;
	isSelected?: boolean;
};

export type ProductOrNounIconProps = RovoLogoProps & {
	productOrNounKey: ProductKeys | NounKeys | undefined;
	isSelected?: boolean;
};

const nounIconStyles = xcss({
	padding: 'space.050',
});

export const ProductIcon = ({
	product,
	size = 'large',
	isSelected = false,
	...props
}: ProductIconProps) => {
	const products = useProductConfigs();
	let Icon;
	if (product) {
		Icon = products[product].Icon;
		return <Icon {...props} size={size} />;
	} else {
		return (
			<AppsIcon
				label=""
				spacing="spacious"
				color={isSelected ? token('color.icon.selected', B400) : token('color.icon', N500)}
			/>
		);
	}
};

export const ProductOrNounIcon = ({
	productOrNounKey,
	size = 'large',
	isSelected = false,
	...props
}: ProductOrNounIconProps) => {
	const products = useProductConfigs();
	let Icon;
	if (productOrNounKey) {
		const isNounFilter = isNounKey(productOrNounKey);

		if (isNounFilter) {
			Icon = nouns[productOrNounKey].Icon;
		} else {
			Icon = products[productOrNounKey as ProductKeys].Icon;
		}

		return isNounFilter ? (
			<Box xcss={nounIconStyles}>
				<Icon {...props} size={size} />
			</Box>
		) : (
			<Icon {...props} size={size} />
		);
	} else {
		return (
			<AppsIcon
				label=""
				spacing="spacious"
				color={isSelected ? token('color.icon.selected', B400) : token('color.icon', N500)}
			/>
		);
	}
};

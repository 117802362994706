import React from 'react';

import { useIntl } from 'react-intl-next';

import { Box, xcss } from '@atlaskit/primitives';

import { type ProductKeys, useProductConfigs } from '../../constants/products';

import { messages } from './messages';
import { OAuthMessage } from './oauth-message';
import { increaseProductDismissCount } from './utils';

const oauthBannerContainerStyles = xcss({
	paddingBottom: 'space.075',
	paddingLeft: 'space.100',
	paddingRight: 'space.100',
});

interface OAuthHandlersProps {
	product: ProductKeys;
	successFlagTitle: React.ReactNode;
	failFlagTitle: React.ReactNode;
	outboundAuthUrl: string;
	onCompletedAction?: () => void;
	onDismissAction?: () => void;
	isOnSearchDialog?: boolean;
}
interface OauthHandlerFunctions {
	onAuthorize: () => void;
	onDismiss: () => void;
}

type useOAuthHandlers = (props: OAuthHandlersProps) => OauthHandlerFunctions;

type OAuthBannerProps = {
	product: ProductKeys;
	outboundAuthUrl: string;
	isOnSearchDialog?: boolean;
	useOAuthHandlers: useOAuthHandlers;
};

export const OAuthBanner = ({
	product,
	outboundAuthUrl,
	isOnSearchDialog,
	useOAuthHandlers,
}: OAuthBannerProps) => {
	const { formatMessage } = useIntl();
	const products = useProductConfigs();
	const [isDismissed, setIsDismissed] = React.useState(false);
	const productString =
		product === 'sharepoint' ? 'SharePoint' : products[product]?.displayName || '';
	const { onAuthorize, onDismiss } = useOAuthHandlers({
		product,
		successFlagTitle: formatMessage(messages.authorizationSuccess, {
			product: productString,
		}),
		failFlagTitle: formatMessage(messages.authorizationFail, {
			product: productString,
		}),
		outboundAuthUrl,
		onCompletedAction: () => {
			setIsDismissed(true);
		},
		onDismissAction: () => {
			setIsDismissed(true);
			increaseProductDismissCount(product);
		},
		isOnSearchDialog,
	});

	if (isDismissed) {
		return null;
	}

	return isOnSearchDialog ? (
		<Box xcss={oauthBannerContainerStyles}>
			<OAuthMessage
				product={product}
				onAuthorize={onAuthorize}
				onDismiss={onDismiss}
				isOnSearchDialog
			/>
		</Box>
	) : (
		<OAuthMessage product={product} onAuthorize={onAuthorize} onDismiss={onDismiss} />
	);
};

import React from 'react';

import { FlagsProvider } from '@atlaskit/flag';
import { fg } from '@atlaskit/platform-feature-flags';

import { type ThirdPartyConfigsBootstrap } from '../../../common/constants/schemas/3p-config';
import { ThirdPartyConfigs } from '../../constants/3p-product-configs';
import { type ProductKeys, ProductKeys3P, PRODUCTS_ORDER } from '../../constants/products';
import { OAuthBanner } from '../../ui/oauth-banner';
import { shouldShowOAuthBanner } from '../../ui/oauth-banner/utils';

interface OAuthHandlersProps {
	product: ProductKeys;
	successFlagTitle: React.ReactNode;
	failFlagTitle: React.ReactNode;
	outboundAuthUrl: string;
	onCompletedAction?: () => void;
	onDismissAction?: () => void;
	isOnSearchDialog?: boolean;
}
interface OauthHandlerFunctions {
	onAuthorize: () => void;
	onDismiss: () => void;
}

type useOAuthHandlers = (props: OAuthHandlersProps) => OauthHandlerFunctions;

interface BannerProps {
	isOnSearchDialog?: boolean;
	thirdPartyConfigs: ThirdPartyConfigsBootstrap;
	availableProducts: ProductKeys[];
	isHello: boolean;
	useOAuthHandlers: useOAuthHandlers;
}

export const OAuthContainer = ({
	isOnSearchDialog,
	thirdPartyConfigs,
	availableProducts,
	isHello,
	useOAuthHandlers,
}: BannerProps) => {
	let productOrder: ProductKeys[] = [];

	if (isOnSearchDialog) {
		productOrder = [
			ProductKeys3P.GoogleDrive,
			ProductKeys3P.Sharepoint,
			ProductKeys3P.Teams,
			ProductKeys3P.Slack,
			ProductKeys3P.Github,
			ProductKeys3P.Figma,
		];
	} else if (fg('rovo-full-page-search-3p-static-config')) {
		const requiredProducts: ProductKeys[] = [];

		ThirdPartyConfigs.forEach((_, productKey3P) => {
			if (ThirdPartyConfigs.get(productKey3P)?.requiresOauth) {
				requiredProducts.push(productKey3P);
			}
		});

		// Filter and order the products based on PRODUCTS_ORDER
		productOrder = PRODUCTS_ORDER.filter((product) => requiredProducts.includes(product));
	} else {
		type ExcludeProductKey = Exclude<keyof typeof ProductKeys3P, 'AtlassianWebCrawler' | 'Loom'>;
		type RequiredProducts = (typeof ProductKeys3P)[ExcludeProductKey][];

		productOrder = [
			ProductKeys3P.GoogleDrive,
			ProductKeys3P.Sharepoint,
			ProductKeys3P.OneDrive,
			ProductKeys3P.Slack,
			ProductKeys3P.Teams,
			ProductKeys3P.Github,
			ProductKeys3P.Figma,
			ProductKeys3P.ConfluenceDC,
		] as RequiredProducts;
	}
	const productsToBeDisplayed = productOrder.filter((product) =>
		availableProducts.includes(product),
	);

	let displayedProduct;

	for (const product of productsToBeDisplayed) {
		const config = thirdPartyConfigs[product as ProductKeys3P];
		if (config && config.userNeedsOAuth && shouldShowOAuthBanner(product, isHello)) {
			displayedProduct = product;
			break;
		}
	}

	const outboundAuthUrl =
		displayedProduct && thirdPartyConfigs[displayedProduct as ProductKeys3P]?.oAuthOutboundUrl;
	return displayedProduct && outboundAuthUrl ? (
		isOnSearchDialog ? (
			<OAuthBanner
				product={displayedProduct}
				outboundAuthUrl={outboundAuthUrl}
				isOnSearchDialog={isOnSearchDialog}
				useOAuthHandlers={useOAuthHandlers}
			/>
		) : (
			<FlagsProvider>
				<OAuthBanner
					product={displayedProduct}
					outboundAuthUrl={outboundAuthUrl}
					useOAuthHandlers={useOAuthHandlers}
				/>
			</FlagsProvider>
		)
	) : null;
};

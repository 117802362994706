import { useEffect, useMemo, useState } from 'react';

import { fg } from '@atlaskit/platform-feature-flags';
import {
	get3pSearchConfig,
	getSearchConfigV2,
	getUserLoomAuthStatus,
	type TSearch3pConfigProps,
	type TSearchConfigAPIResponse,
} from '@atlassian/search-client';

import { type ProductKeys3P, ThirdPartyConfigs } from '../../common/constants/3p-product-configs';
import { nouns as supportedNouns } from '../../common/constants/nouns';
import {
	allProductKeys,
	type PrimaryProductKey,
	PrimaryProductKeys,
	ProductKeys,
	useProductConfigs,
} from '../../common/constants/products';
import {
	type ThirdPartyConfigsBootstrap,
	type ThirdPartyConfigsWithAPIResponse,
} from '../../common/constants/schemas/3p-config';
import { useAnalytics } from '../analytics';
import { useLogException } from '../error-boundary';

const EXTRACT_PRODUCT = /ari:cloud:(.*)?::/;
const EXTRACT_CLOUD_ID = /ari:cloud:.*?\/(.*)/;
const BITBUCKET_WORKSPACE_ARI = 'ari:cloud:bitbucket::workspace';

export type UseFetchConfigProps = {
	thirdPartyAbsoluteUrl?: string;
	thirdPartyDisabled?: boolean;
	userId?: string | null;
	cloudId?: string;
	aggAbsoluteUrl?: string;
	primaryProduct?: PrimaryProductKey;
	/**
	 * When passed this would be used as is to invoke the endpoint which returns search-configuration i.e. list of products accessible by the logged in user.
	 * This is useful for products like Trello where the API gateway is not on the host domain, but a completely different URL.
	 *
	 * No need to pass if the UI is being bootstrapped inside Jira, Confluence or any product tied to these sites.
	 */
	configFetchAbsoluteUrl?: string;
	aggregatorHostName?: string;
};

const getFirstCloudId = (firstPartyResponse: TSearchConfigAPIResponse) => {
	const match = firstPartyResponse.resources[0]?.id.match(EXTRACT_CLOUD_ID);
	const extractedCloudId = match ? match[1] : '';

	return extractedCloudId;
};

const isNounsAvailable = (firstPartyResponse?: TSearchConfigAPIResponse, cloudId?: string) => {
	if (!cloudId || !firstPartyResponse || !firstPartyResponse.siteMetadata) {
		return false;
	}
	return firstPartyResponse.siteMetadata.find((metadata) => metadata.siteId === cloudId)
		?.isNounsAvailable;
};

const isRovoEnabled = (firstPartyResponse?: TSearchConfigAPIResponse, cloudId?: string) => {
	if (!cloudId || !firstPartyResponse || !firstPartyResponse.siteMetadata) {
		return false;
	}
	return firstPartyResponse.siteMetadata.find((metadata) => metadata.siteId === cloudId)
		?.isRovoEnabled;
};

/**
 * A React hook which handles fetching of all the config necessary to bootstrap 1P + 3P results.
 */
export const useFetchConfig = ({
	cloudId: cloudIdProp,
	configFetchAbsoluteUrl,
	aggregatorHostName,
	userId,
	thirdPartyAbsoluteUrl,
	thirdPartyDisabled,
	primaryProduct,
}: UseFetchConfigProps) => {
	const supportedProducts = useProductConfigs();
	const [firstPartyResponse, setFirstPartyResponse] = useState<TSearchConfigAPIResponse>();
	const [isUserLoomAuthed, setIsUserLoomAuthed] = useState<boolean>(false);

	const [accessibleProductsConfig, setAccessibleProductsConfig] = useState<{
		products?: ProductKeys[];
		nouns: string[];
		thirdPartyConfigs?: ThirdPartyConfigsBootstrap;
	}>({
		nouns: [],
	});
	const { fireAnalyticsEvent } = useAnalytics();

	const logException = useLogException();

	const firstCloudId = useMemo(() => {
		if (!cloudIdProp && firstPartyResponse) {
			return getFirstCloudId(firstPartyResponse);
		}
		return '';
	}, [firstPartyResponse, cloudIdProp]);

	const thirdPartyResponse = useThirdPartyConfig({
		cloudId: cloudIdProp || firstCloudId || '',
		userId: userId,
		absoluteUrl: thirdPartyAbsoluteUrl,
		disabled: thirdPartyDisabled,
	});

	useEffect(() => {
		//this endpoint only works in confluence and atlas, show loom to other products for now
		if (!isUserLoomAuthed) {
			if (
				primaryProduct === PrimaryProductKeys.Confluence ||
				primaryProduct === PrimaryProductKeys.Atlas
			) {
				getUserLoomAuthStatus({
					userId,
					cloudId: cloudIdProp,
				})
					.then((response) => setIsUserLoomAuthed(response?.status === 'mastered'))
					.catch((error) => {
						logException(error);

						setIsUserLoomAuthed(false);
					});
			} else {
				setIsUserLoomAuthed(true);
			}
		}
	}, [isUserLoomAuthed, setIsUserLoomAuthed, userId, logException, cloudIdProp, primaryProduct]);

	useEffect(() => {
		if (!firstPartyResponse) {
			const searchConfigParams = {
				cloudId: cloudIdProp,
				absoluteUrl: configFetchAbsoluteUrl,
				hostName: aggregatorHostName,
				userId,
			};

			getSearchConfigV2(searchConfigParams)
				.then((response) => setFirstPartyResponse(response))
				.catch((error) => {
					logException(error);

					setFirstPartyResponse({
						resources: [],
						intercomHmac: '',
						siteMetadata: [],
					});
				});
		}
	}, [
		firstPartyResponse,
		setFirstPartyResponse,
		cloudIdProp,
		configFetchAbsoluteUrl,
		aggregatorHostName,
		userId,
		logException,
	]);

	useEffect(() => {
		if (
			thirdPartyResponse.productKeys &&
			firstPartyResponse &&
			!accessibleProductsConfig.products
		) {
			let allAccessibleProducts: ProductKeys[] = [];

			const firstPartyProducts = [
				...new Set(
					firstPartyResponse.resources.flatMap((resource) => {
						const { id } = resource;
						const match = id.match(EXTRACT_PRODUCT);

						if (match && match.length >= 2 && match[1] in supportedProducts) {
							return [match[1]];
						}

						return [];
					}),
				),
			];

			if (firstPartyProducts.length === 0 && primaryProduct) {
				// Use the primary product if the 1P Search Config request fails
				allAccessibleProducts.push(primaryProduct as ProductKeys);
			} else {
				allAccessibleProducts.push(...(firstPartyProducts as ProductKeys[]));
			}

			// We are currently migrating Goal and Project nouns from townsquare (Atlas) into their own separate Products
			// within Identity. As part of this migration we are decommissioning the isNounsAvailable flag on siteMetadata
			// and moving the nouns into the first party product list.
			//
			// If a site has isNounsAvailable set to false and the feature gate is set then we want to return the available
			// nouns from the firstPartyResponse. If isNounsAvailable is true then the site hasn't been migrated yet and
			// should continue with the old behaviour.
			const areNounsEnabledThroughLegacyFlag = isNounsAvailable(firstPartyResponse, cloudIdProp);
			const useProductAppNouns =
				!areNounsEnabledThroughLegacyFlag && fg('enable_identity_powered_nouns');

			const accessibleNouns = useProductAppNouns
				? firstPartyResponse.resources.flatMap((resource) => {
						const { id } = resource;
						const match = id.match(EXTRACT_PRODUCT);

						if (match && match.length >= 2 && match[1] in supportedNouns) {
							return [match[1]];
						}

						return [];
					})
				: [];

			// If nouns are available we also need to add Townsquare to the list of accessible products.
			// This way Projects will be fetched by default when no filters are selected.
			if (accessibleNouns.length > 0 && useProductAppNouns) {
				allAccessibleProducts.push(ProductKeys.Atlas);
			}

			// Remove Bitbucket from the list of resources if:
			// * The user doesn't have access to the Bitbucket product
			// * There are no bitbucket workspaces
			// See https://hello.atlassian.net/wiki/x/1hI6CQE for details
			if (
				allAccessibleProducts.includes('bitbucket') &&
				!firstPartyResponse.resources.find((r) => r.id.includes(BITBUCKET_WORKSPACE_ARI))
			) {
				allAccessibleProducts = allAccessibleProducts.filter(
					(product) => product !== ProductKeys.Bitbucket,
				);
			}

			// user may have loom entitlement but not the correct auth status
			if (!isUserLoomAuthed) {
				allAccessibleProducts = allAccessibleProducts.filter(
					(product) => product !== ProductKeys.Loom,
				);
			}

			// Allow 3P products only if a user has Rovo enabled.
			// Long-term it won't be needed if the 3p-configuration check ensures Rovo eligibility.
			// See https://hello.atlassian.net/wiki/spaces/SEARCH/pages/4469899124.
			const doesUserHaveRovo = isRovoEnabled(firstPartyResponse, cloudIdProp);
			if (doesUserHaveRovo) {
				const thirdPartyProductKeys = thirdPartyResponse.productKeys || [];
				allAccessibleProducts.push(...(thirdPartyProductKeys as ProductKeys[]));
				setAccessibleProductsConfig({
					products: allAccessibleProducts,
					nouns: accessibleNouns,
					thirdPartyConfigs: thirdPartyResponse.thirdPartyConfigs,
				});
			} else {
				setAccessibleProductsConfig({
					products: allAccessibleProducts,
					nouns: accessibleNouns,
					thirdPartyConfigs: {},
				});
			}
		}
	}, [
		isUserLoomAuthed,
		thirdPartyResponse,
		firstPartyResponse,
		setAccessibleProductsConfig,
		primaryProduct,
		fireAnalyticsEvent,
		logException,
		cloudIdProp,
		accessibleProductsConfig.products,
		supportedProducts,
	]);

	return {
		accessibleProducts: accessibleProductsConfig.products,
		accessibleNouns: accessibleProductsConfig.nouns,
		thirdPartyConfigs: accessibleProductsConfig.thirdPartyConfigs,
		firstCloudId,
		intercomHmac: firstPartyResponse?.intercomHmac,
		isNounsAvailable: isNounsAvailable(firstPartyResponse, cloudIdProp),
		isRovoEnabled: isRovoEnabled(firstPartyResponse, cloudIdProp),
	};
};

export const THIRD_PARTY_TYPES_ORIGINAL: Record<string, string> = {
	'ai-3p-connector:google-workspace': `drive`,
	'ai-3p-connector:sharepoint-domain': `sharepoint`,
	'ai-3p-connector:slack-workspace': 'slack',
	//platform connector types use a different naming convention
	'google-drive-connector': `drive`,
	'sharepoint-connector': `sharepoint`,
	'webcrawler-connector': 'atlassian-web-crawler',
	'microsoft-teams-connector': 'teams',
	'github-connector': 'github',
	'figma-connector': 'figma',
	'onedrive-connector': 'onedrive',
	'dropbox-connector': 'dropbox',
	'google-calendar-connector': 'google-calendar',
	'gmail-connector': 'gmail',
	'airtable-connector': 'airtable',
	'smartsheet-connector': 'smartsheet',
	'notion-connector': 'notion',
	'miro-connector': 'miro',
	'zendesk-connector': 'zendesk',
	'docusign-connector': 'docusign',
	'lucid-connector': 'lucid',
	'monday-connector': 'monday',
	'azure-devops-connector': 'azure-devops',
	'gitlab-connector': 'gitlab',
	'confluence-dc-connector': 'confluence-dc',
	'canva-connector': 'canva',
	'outlook-calendar-connector': 'outlook-calendar',
	'outlook-mail-connector': 'outlook-mail',
	'asana-connector': 'asana',
	'box-connector': 'box',
	'smartlinks-connector-zeplin': 'zeplin',
	'servicenow-connector': 'servicenow',
	'freshservice-connector': 'freshservice',
	'smartlinks-connector-power-bi': 'power-bi',
	'smartlinks-connector-clickup': 'clickup',
	'smartlinks-connector-amplitude': 'amplitude',
	'smartlinks-connector-stripe': 'stripe',
	'smartlinks-connector-adobexd': 'adobexd',
	'aha-connector': 'aha',
	'adobe-sign-connector': 'adobe-sign',
	'smartlinks-connector-mural': 'mural',
	'smartlinks-connector-webex': 'webex',
	'smartlinks-connector-todoist': 'todoist',
	'smartlinks-connector-pagerduty': 'pagerduty',
	'smartlinks-connector-pipedrive': 'pipedrive',
	'smartlinks-connector-dovetail': 'dovetail',
} as const;

// Conditionally create the dynamic third-party types
const getThirdPartyTypesFromConfig = (): Record<string, string> => {
	// old sharepoint product key is put here (kept for backwards compatibility), as the new one is in the config
	const thirdPartyTypes: Record<string, string> = {
		'ai-3p-connector:sharepoint-domain': `sharepoint`,
		'ai-3p-connector:google-workspace': `drive`,
	};

	ThirdPartyConfigs.forEach((config) => {
		if (config.resourceType) {
			thirdPartyTypes[config.resourceType] = config.id;
		}
	});

	return thirdPartyTypes;
};

export const getThirdPartyTypes = (): Record<string, string> => {
	return fg('rovo-full-page-search-3p-static-config')
		? getThirdPartyTypesFromConfig()
		: THIRD_PARTY_TYPES_ORIGINAL;
};

const get3pProductKeys = async ({ cloudId, absoluteUrl, userId }: TSearch3pConfigProps) => {
	const THIRD_PARTY_TYPES: Record<string, string> = getThirdPartyTypes();
	const resources = await get3pSearchConfig({ cloudId, absoluteUrl, userId });

	let productKeys = [] as ProductKeys3P[];
	let thirdPartyConfigsWithAPIResponse: ThirdPartyConfigsBootstrap = {};

	resources.forEach((resource) => {
		const productKey = THIRD_PARTY_TYPES[resource.type] || '';
		if (productKey) {
			productKeys.push(productKey as ProductKeys3P);
			let config = {} as ThirdPartyConfigsWithAPIResponse;
			const staticConfig = ThirdPartyConfigs.get(productKey as ProductKeys3P);
			if (staticConfig) {
				config = {
					...staticConfig,
					oAuthOutboundUrl: resource.outboundAuthUrl,
					userNeedsOAuth: !resource.isUserOAuthed,
					providerId: resource.providerId,
					workspaceName: resource.workspaceName,
				};
				thirdPartyConfigsWithAPIResponse[productKey as ProductKeys3P] = config;
			}
		}
	});

	productKeys = productKeys.filter((productKey) => allProductKeys.includes(productKey));

	return { productKeys, thirdPartyConfigs: thirdPartyConfigsWithAPIResponse };
};

const EMPTY_RESPONSE: string[] = [];

export const useThirdPartyConfig = ({
	cloudId,
	absoluteUrl,
	userId,
	disabled,
}: Partial<TSearch3pConfigProps> = {}) => {
	const [config, setConfig] = useState<{
		productKeys?: string[];
		thirdPartyConfigs?: ThirdPartyConfigsBootstrap;
	}>({});
	const logException = useLogException();

	useEffect(() => {
		if (!config.productKeys) {
			if (!disabled && cloudId) {
				get3pProductKeys({ cloudId, absoluteUrl, userId })
					.then((data) => {
						setConfig({
							productKeys: data.productKeys,
							thirdPartyConfigs: data.thirdPartyConfigs,
						});
					})
					.catch((error) => {
						setConfig({
							productKeys: EMPTY_RESPONSE,
							thirdPartyConfigs: {},
						});

						logException(error);
					});
			} else {
				setConfig({
					productKeys: EMPTY_RESPONSE,
					thirdPartyConfigs: {},
				});
			}
		}
	}, [cloudId, absoluteUrl, userId, disabled, config, logException]);

	return config;
};

/**
 * @deprecated do not use or change this file, use the new third-party-graph-entity package instead
 */
import type { Maybe, SearchResult } from '../../../../common/types';
import type { ThirdPartyUser } from '../types';

const GOOGLE_INTEGRATION_ARI = 'ari:cloud:platform::integration/google';
const MICROSOFT_INTEGRATION_ARI = 'ari:cloud:platform::integration/microsoft';

const SearchThirdPartyDocumentPartial = `
... on SearchResultGoogleDocument {
  lastModifiedDate
  excerpt
  permissionLevel
}
... on SearchResultGooglePresentation {
  lastModifiedDate
  excerpt
	permissionLevel
}
... on SearchResultGoogleSpreadsheet {
  lastModifiedDate
  excerpt
	permissionLevel
}
... on SearchResultGooglePresentation {
  lastModifiedDate
  excerpt
	permissionLevel
}
... on SearchResultGoogleSpreadsheet {
  lastModifiedDate
  excerpt
	permissionLevel
}
... on SearchResultMicrosoftDocument {
  lastModifiedDate
  excerpt
  permissionLevel
}
`;

const SlackMessagePartial = `
... on SearchResultSlackMessage {
  lastModifiedDate
  subtype
  mentions {
    name
	externalId
  }
  initialContributors {
    name
  }
  linkedEntities {
	id
	title
	description
	initialContributors {
  	  name
	  externalId
	}
	mentions {
	  name
	  externalId
	}
  }
}
`;

export type ThirdPartyRestrictionLevel = 'restricted' | 'public' | 'private';

export interface SearchThirdPartyDocumentPartial extends SearchResult {
	lastModifiedDate: string;
	excerpt: string;
	permissionLevel: Maybe<ThirdPartyRestrictionLevel>;
	mentions?: ThirdPartyUser[] | undefined;
}

export interface SearchResultSlackMessage extends SearchResult {
	lastModifiedDate: string;
	channelName: string;
	mentions: ThirdPartyUser[];
	initialContributors: ThirdPartyUser[];
	permissionLevel: Maybe<ThirdPartyRestrictionLevel>;
	excerpt: string;
	lastActive: Date;
	linkedEntities: SearchResultSlackMessage[];
	allContributors: ThirdPartyUser[];
	subtype: string;
}

export interface SearchResultGraphDocument extends SearchResult {
	excerpt: string;
	permissionLevel: Maybe<ThirdPartyRestrictionLevel>;
	subtype: string;
	integrationId: string;
	lastModifiedDate: string;
	initialContributors: {
		name: string;
	}[];
}

export const getGooglePartials = () => {
	return SearchThirdPartyDocumentPartial;
};

export const getMicrosoftPartials = () => {
	return SearchThirdPartyDocumentPartial;
};

export const getSlackPartials = () => {
	return `${SlackMessagePartial}`;
};

export const isSharepointResult = (partial: SearchResult) =>
	partial.__typename === 'SearchResultMicrosoftDocument' ||
	(isGraphDocument(partial) && partial.integrationId === MICROSOFT_INTEGRATION_ARI);

export const isSharepointDocument = (partial: SearchResult) =>
	(isSharepointResult(partial) && partial.type === 'document') ||
	(isGraphDocument(partial) &&
		partial.integrationId === MICROSOFT_INTEGRATION_ARI &&
		partial.subtype === 'document');

export const isSharepointPresentation = (partial: SearchResult) =>
	(isSharepointResult(partial) && partial.type === 'presentation') ||
	(isGraphDocument(partial) &&
		partial.integrationId === MICROSOFT_INTEGRATION_ARI &&
		partial.subtype === 'presentation');

export const isSharepointSpreadsheet = (partial: SearchResult) =>
	(isSharepointResult(partial) && partial.type === 'spreadsheet') ||
	(isGraphDocument(partial) &&
		partial.integrationId === MICROSOFT_INTEGRATION_ARI &&
		partial.subtype === 'spreadsheet');

export const isOneDriveResult = (partial: SearchResult) =>
	isGraphDocument(partial) && partial.integrationId === MICROSOFT_INTEGRATION_ARI;

export const isOneDriveDocument = (partial: SearchResult) =>
	isGraphDocument(partial) &&
	partial.integrationId === MICROSOFT_INTEGRATION_ARI &&
	(partial.type === 'document' || partial.subtype === 'document');

export const isOneDrivePresentation = (partial: SearchResult) =>
	isGraphDocument(partial) &&
	partial.integrationId === MICROSOFT_INTEGRATION_ARI &&
	(partial.type === 'presentation' || partial.subtype === 'presentation');

export const isOneDriveSpreadsheet = (partial: SearchResult) =>
	isGraphDocument(partial) &&
	partial.integrationId === MICROSOFT_INTEGRATION_ARI &&
	(partial.type === 'spreadsheet' || partial.subtype === 'spreadsheet');

export const isGoogleResult = (partial: SearchResult) =>
	isGoogleDocument(partial) || isGooglePresentation(partial) || isGoogleSpreadsheet(partial);

export const isGoogleDocument = (partial: SearchResult) =>
	partial.__typename === 'SearchResultGoogleDocument' ||
	(isGraphDocument(partial) &&
		partial.integrationId === GOOGLE_INTEGRATION_ARI &&
		partial.subtype === 'document');

export const isGooglePresentation = (partial: SearchResult) =>
	partial.__typename === 'SearchResultGooglePresentation' ||
	(isGraphDocument(partial) &&
		partial.integrationId === GOOGLE_INTEGRATION_ARI &&
		partial.subtype === 'presentation');

export const isGoogleSpreadsheet = (partial: SearchResult) =>
	partial.__typename === 'SearchResultGoogleSpreadsheet' ||
	(isGraphDocument(partial) &&
		partial.integrationId === GOOGLE_INTEGRATION_ARI &&
		partial.subtype === 'spreadsheet');

export const isSlackMessage = (partial: SearchResult): partial is SearchResultSlackMessage =>
	partial.__typename === 'SearchResultSlackMessage';

export const isSearchThirdPartyDocumentPartial = (
	partial: SearchResult,
): partial is SearchThirdPartyDocumentPartial => {
	return isGoogleResult(partial) || isSharepointResult(partial) || isGraphDocument(partial);
};

export const isGraphDocument = (partial: SearchResult): partial is SearchResultGraphDocument =>
	partial.__typename === 'SearchResultGraphDocument';

import { fg } from '@atlaskit/platform-feature-flags';
import {
	type ConfluenceRangeFilter,
	type ThirdPartyFilters,
	type ThirdPartyProductFilter,
} from '@atlassian/search-client';

import { ThirdPartyConfigs } from '../../constants/3p-product-configs';
import { type FilterId } from '../../constants/filters';
import { TypeFilterValueKey } from '../../constants/filters/universal-type';
import { is3pProductKey, type ProductKeys, type ProductKeys3P } from '../../constants/products';
import { type ThirdPartyConfigsBootstrap } from '../../constants/schemas/3p-config';
import { ProductKey } from '../../constants/schemas/query-params';
import type { SelectedFiltersType } from '../../constants/types';

import { getTypeMapping } from './constants';

const GOOGLE_INTEGRATION_ARI = 'ari:cloud:platform::integration/google';
const MICROSOFT_INTEGRATION_ARI = 'ari:cloud:platform::integration/microsoft';
const WEB_CRAWLER_INTEGRATION_ARI = 'ari:cloud:platform::integration/atlassian-web-crawler';
const GITHUB_INTEGRATION_ARI = 'ari:cloud:platform::integration/github';
const TEAMS_INTEGRATION_ARI = 'ari:cloud:platform::integration/microsoft';
const FIGMA_INTEGRATION_ARI = 'ari:cloud:platform::integration/figma';
const LOOM_INTEGRATION_ARI = 'ari:cloud:platform::integration/loom';
const DROPBOX_INTEGRATION_ARI = 'ari:cloud:platform::integration/dropbox';
const SLACK_INTEGRATION_ARI = 'ari:cloud:platform::integration/slack';
const AIRTABLE_INTEGRATION_ARI = 'ari:cloud:platform::integration/airtable';
const SMARTSHEET_INTEGRATION_ARI = 'ari:cloud:platform::integration/smartsheet';
const BITBUCKET_INTEGRATION_ARI = 'ari:cloud:platform::integration/bitbucket';
const NOTION_INTEGRATION_ARI = 'ari:cloud:platform::integration/notion';
const MIRO_INTEGRATION_ARI = 'ari:cloud:platform::integration/miro';
const ZENDESK_INTEGRATION_ARI = 'ari:cloud:platform::integration/zendesk';
const DOCUSIGN_INTEGRATION_ARI = 'ari:cloud:platform::integration/docusign';
const LUCID_INTEGRATION_ARI = 'ari:cloud:platform::integration/lucid';
const MONDAY_INTEGRATION_ARI = 'ari:cloud:platform::integration/monday';
const AZURE_DEVOPS_INTEGRATION_ARI = 'ari:cloud:platform::integration/azure-devops';
const GITLAB_INTEGRATION_ARI = 'ari:cloud:platform::integration/gitlab';
const CONFLUENCE_DC_INTEGRATION_ARI = 'ari:cloud:platform::integration/confluence-dc';
const CANVA_INTEGRATION_ARI = 'ari:cloud:platform::integration/canva';
const ASANA_INTEGRATION_ARI = 'ari:cloud:platform::integration/asana';
const BOX_INTEGRATION_ARI = 'ari:cloud:platform::integration/box';
const ZEPLIN_INTEGRATION_ARI = 'ari:cloud:platform::integration/zeplin';
const SERVICENOW_INTEGRATION_ARI = 'ari:cloud:platform::integration/servicenow';
const FRESHSERVICE_INTEGRATION_ARI = 'ari:cloud:platform::integration/freshservice';
const POWERBI_INTEGRATION_ARI = 'ari:cloud:platform::integration/microsoft';
const CLICKUP_INTEGRATION_ARI = 'ari:cloud:platform::integration/clickup';
const AMPLITUDE_INTEGRATION_ARI = 'ari:cloud:platform::integration/amplitude';
const STRIPE_INTEGRATION_ARI = 'ari:cloud:platform::integration/stripe';
const ADOBE_INTEGRATION_ARI = 'ari:cloud:platform::integration/adobe';
const AHA_INTEGRATION_ARI = 'ari:cloud:platform::integration/aha';
const MURAL_INTEGRATION_ARI = 'ari:cloud:platform::integration/mural';
const WEBEX_INTEGRATION_ARI = 'ari:cloud:platform::integration/webex';
const TODOIST_INTEGRATION_ARI = 'ari:cloud:platform::integration/todoist';
const PAGERDUTY_INTEGRATION_ARI = 'ari:cloud:platform::integration/pagerduty';
const PIPEDRIVE_INTEGRATION_ARI = 'ari:cloud:platform::integration/pipedrive';
const DOVETAIL_INTEGRATION_ARI = 'ari:cloud:platform::integration/dovetail';

export const getEmptySelectedFilters = (): SelectedFiltersType => ({
	commonFilters: {},
	confluenceFilters: {
		spacesFilter: [],
		contributorsFilter: [],
		ancestorIdsFilter: [],
		range: [],
		owners: [],
		labelsFilter: [],
		titleMatchOnly: false,
	},
	jiraFilters: {
		issueFilter: {
			assigneeARIs: [],
			projectARIs: [],
			issueTypeIDs: [],
		},
	},
	mercuryFilters: {
		owners: [],
		focusAreaTypeIds: [],
	},
	thirdPartyFilters: {
		titleMatchOnly: false,
		subtypes: [],
		integrations: [],
	},
});

export const convertCustomDateToRange = (from?: string, to?: string) => {
	const fromDate = from ? new Date(from) : new Date();
	const startOfTheFromDate = new Date(
		fromDate.getFullYear(),
		fromDate.getMonth(),
		fromDate.getDate(),
		0,
		0,
		0,
		0,
	);
	const toDate = to ? new Date(to) : new Date();
	const endOfTheToDate = new Date(
		toDate.getFullYear(),
		toDate.getMonth(),
		toDate.getDate(),
		23,
		59,
		59,
		999,
	);
	return {
		gt: from ? startOfTheFromDate.toISOString().split('Z')[0] : undefined,
		lt: to ? endOfTheToDate.toISOString().split('Z')[0] : undefined,
	};
};

// Copied from Confluence advanced-search package
// generates truncated iso strings for Confluence last modified filter defaults (today, yesterday, etc)
// considering the user's locale-based definition of start OR end of day
export const getUTCDateRange = (fromOffset: number, toOffset: number) => {
	const currentDate = new Date();
	return {
		gt: new Date(
			new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()).getTime() -
				fromOffset * 24 * 60 * 60 * 1000,
		)
			.toISOString()
			.split('Z')[0],
		lt: new Date(
			new Date(
				currentDate.getFullYear(),
				currentDate.getMonth(),
				currentDate.getDate(),
				23,
				59,
				59,
				999,
			).getTime() -
				toOffset * 24 * 60 * 60 * 1000,
		)
			.toISOString()
			.split('Z')[0],
	};
};

export const convertLastUpdatedToDateRange = (lastUpdated: string) => {
	let dateRangeValues;
	switch (lastUpdated) {
		case 'today':
			dateRangeValues = getUTCDateRange(0, 0);
			break;
		case 'yesterday':
			dateRangeValues = getUTCDateRange(1, 1);
			break;
		case 'past7Days':
			dateRangeValues = getUTCDateRange(7, 0);
			break;
		case 'past30Days':
			dateRangeValues = getUTCDateRange(30, 0);
			break;
		case 'pastYear':
			dateRangeValues = getUTCDateRange(365, 0);
			break;
		default:
			dateRangeValues = { gt: '', lt: '' };
	}
	return dateRangeValues;
};

export const getConfluenceDateOptionFromCustomDates = (
	from: string,
	to: string,
): ConfluenceRangeFilter => {
	const { gt, lt } = convertCustomDateToRange(from, to);
	return {
		field: 'LASTMODIFIED',
		gt,
		lt,
	};
};

export const getConfluenceDateOptionValues = (
	selectedDateOption: string,
): ConfluenceRangeFilter => {
	const dateRangeValues = convertLastUpdatedToDateRange(selectedDateOption);
	const { gt, lt } = dateRangeValues;
	return {
		field: 'LASTMODIFIED',
		gt,
		lt,
	};
};

export type IntegrationMap = Partial<Record<ProductKeys, string>>;

export const baseIntegrationARIs: IntegrationMap = {
	[ProductKey.GoogleDrive]: GOOGLE_INTEGRATION_ARI,
	[ProductKey.Sharepoint]: MICROSOFT_INTEGRATION_ARI,
	[ProductKey.AtlassianWebCrawler]: WEB_CRAWLER_INTEGRATION_ARI,
	[ProductKey.Teams]: TEAMS_INTEGRATION_ARI,
	[ProductKey.Figma]: FIGMA_INTEGRATION_ARI,
	[ProductKey.Loom]: LOOM_INTEGRATION_ARI,
	[ProductKey.OneDrive]: MICROSOFT_INTEGRATION_ARI,
	[ProductKey.Github]: GITHUB_INTEGRATION_ARI,
	[ProductKey.Bitbucket]: BITBUCKET_INTEGRATION_ARI,
	[ProductKey.Airtable]: AIRTABLE_INTEGRATION_ARI,
	[ProductKey.Smartsheet]: SMARTSHEET_INTEGRATION_ARI,
	[ProductKey.Notion]: NOTION_INTEGRATION_ARI,
	[ProductKey.Miro]: MIRO_INTEGRATION_ARI,
	[ProductKey.Zendesk]: ZENDESK_INTEGRATION_ARI,
	[ProductKey.Docusign]: DOCUSIGN_INTEGRATION_ARI,
	[ProductKey.Lucid]: LUCID_INTEGRATION_ARI,
	[ProductKey.Monday]: MONDAY_INTEGRATION_ARI,
	[ProductKey.AzureDevops]: AZURE_DEVOPS_INTEGRATION_ARI,
	[ProductKey.Gitlab]: GITLAB_INTEGRATION_ARI,
	[ProductKey.ConfluenceDC]: CONFLUENCE_DC_INTEGRATION_ARI,
	[ProductKey.Canva]: CANVA_INTEGRATION_ARI,
	[ProductKey.OutlookCalendar]: MICROSOFT_INTEGRATION_ARI,
	[ProductKey.Asana]: ASANA_INTEGRATION_ARI,
	[ProductKey.Zeplin]: ZEPLIN_INTEGRATION_ARI,
	[ProductKey.ServiceNow]: SERVICENOW_INTEGRATION_ARI,
	[ProductKey.Freshservice]: FRESHSERVICE_INTEGRATION_ARI,
	[ProductKey.PowerBI]: POWERBI_INTEGRATION_ARI,
	[ProductKey.ClickUp]: CLICKUP_INTEGRATION_ARI,
	[ProductKey.Amplitude]: AMPLITUDE_INTEGRATION_ARI,
	[ProductKey.Stripe]: STRIPE_INTEGRATION_ARI,
	[ProductKey.AdobeXD]: ADOBE_INTEGRATION_ARI,
	[ProductKey.Aha]: AHA_INTEGRATION_ARI,
	[ProductKey.AdobeSign]: ADOBE_INTEGRATION_ARI,
	[ProductKey.Mural]: MURAL_INTEGRATION_ARI,
	[ProductKey.Webex]: WEBEX_INTEGRATION_ARI,
	[ProductKey.Todoist]: TODOIST_INTEGRATION_ARI,
	[ProductKey.PagerDuty]: PAGERDUTY_INTEGRATION_ARI,
	[ProductKey.Pipedrive]: PIPEDRIVE_INTEGRATION_ARI,
	[ProductKey.Dovetail]: DOVETAIL_INTEGRATION_ARI,
};

export const getIntegrationARIsFromConfig = (): IntegrationMap => {
	const integrationMap: IntegrationMap = {};

	ThirdPartyConfigs.forEach((config, productKey3P) => {
		if (config.isEnabled()) {
			integrationMap[productKey3P] = config.integrationAri;
		}
	});

	return integrationMap;
};

export const getThirdPartyFilters = (
	products: ProductKeys[],
	titleMatchValue: boolean,
	// entities is the frontend filtered, already selected values
	entities: TypeFilterValueKey[],
	thirdPartyConfigs: ThirdPartyConfigsBootstrap,
	baseIntegrationARIs: IntegrationMap,
	range?: ConfluenceRangeFilter[],
): ThirdPartyFilters => {
	if (!products.length || products.some((p) => is3pProductKey(p) || p === ProductKey.Bitbucket)) {
		const typeMapping = getTypeMapping();
		// Conditionally create the dynamic integration ARIs based on the config
		const integrationARIsFromConfig = getIntegrationARIsFromConfig();

		// Combine static and dynamic integration ARIs
		let productToIntegrationARIMap: IntegrationMap = fg('rovo-full-page-search-3p-static-config')
			? integrationARIsFromConfig
			: baseIntegrationARIs;

		// this is not covered under 3p static config
		if (fg('enable_rovo_search_bitbucket')) {
			productToIntegrationARIMap = {
				...productToIntegrationARIMap,
				[ProductKey.Bitbucket]: BITBUCKET_INTEGRATION_ARI,
			};
		}

		if (!fg('rovo-full-page-search-3p-static-config')) {
			if (fg('enable_3p_search_dropbox')) {
				productToIntegrationARIMap = {
					...productToIntegrationARIMap,
					[ProductKey.Dropbox]: DROPBOX_INTEGRATION_ARI,
				};
			}
			if (fg('enable_3p_search_box')) {
				productToIntegrationARIMap = {
					...productToIntegrationARIMap,
					[ProductKey.Box]: BOX_INTEGRATION_ARI,
				};
			}
			if (fg('enable_3p_search_google_calendar')) {
				productToIntegrationARIMap = {
					...productToIntegrationARIMap,
					[ProductKey.GoogleCalendar]: GOOGLE_INTEGRATION_ARI,
				};
			}
			if (fg('enable_3p_search_outlook_calendar')) {
				productToIntegrationARIMap = {
					...productToIntegrationARIMap,
					[ProductKey.OutlookCalendar]: MICROSOFT_INTEGRATION_ARI,
				};
			}
			if (fg('enable_3p_search_outlook_mail')) {
				productToIntegrationARIMap = {
					...productToIntegrationARIMap,
					[ProductKey.OutlookMail]: MICROSOFT_INTEGRATION_ARI,
				};
			}
			if (fg('enable_3p_search_gmail')) {
				productToIntegrationARIMap = {
					...productToIntegrationARIMap,
					[ProductKey.Gmail]: GOOGLE_INTEGRATION_ARI,
				};
			}
			// for Slack's static config, isEnabled is set to true, and not based on 'disable_slack_in_all_filter'
			const isAllSelectedAndSlackAllDisabled =
				products.length > 1 && fg('disable_slack_in_all_filter');
			if (!isAllSelectedAndSlackAllDisabled) {
				productToIntegrationARIMap = {
					...productToIntegrationARIMap,
					[ProductKey.Slack]: SLACK_INTEGRATION_ARI,
				};
			}
		}

		// get the subtypes by looking at each type's subcategories
		const subtypes = entities.flatMap((entity) => typeMapping[entity]?.subCategories ?? []);

		let thirdPartyProducts = fg('providerid-product-identifier')
			? (products
					.filter(
						(product) =>
							is3pProductKey(product) &&
							thirdPartyConfigs[product] !== undefined &&
							!thirdPartyConfigs[product as ProductKeys3P]?.userNeedsOAuth &&
							!thirdPartyConfigs[product as ProductKeys3P]?.isFederated,
					)
					.map((product) => {
						const thirdPartyConfig = thirdPartyConfigs[product as ProductKeys3P];
						if (!thirdPartyConfig) {
							// this wont happen because of the above filter,
							// but typescript doesnt believe it.
							return undefined;
						}
						const integrationId = thirdPartyConfig.integrationAri;
						const providerId = thirdPartyConfig.providerId;
						return {
							integrationId,
							subtypes,
							providerId,
							product,
						};
					}) as ThirdPartyProductFilter[])
			: undefined;

		if (thirdPartyProducts) {
			// Add Loom and Bitbucket to the thirdPartyProducts filter
			const firstPartyProductsFor3pFilters: ProductKeys[] = [ProductKey.Loom, ProductKey.Bitbucket];

			const result = firstPartyProductsFor3pFilters
				.filter(
					(product) =>
						products.includes(product) && !thirdPartyProducts?.some((p) => p.product === product),
				)
				.map((product) => ({
					integrationId: productToIntegrationARIMap[product] || '',
					subtypes,
					product,
				}));
			thirdPartyProducts = thirdPartyProducts.concat(result);
		}

		if (Object.keys(productToIntegrationARIMap).length !== 0) {
			const integrationARIs: string[] = products
				.filter(
					(product) =>
						!is3pProductKey(product) ||
						!thirdPartyConfigs[product as ProductKeys3P]?.userNeedsOAuth,
				)
				.map((product) => productToIntegrationARIMap[product])
				.filter((ari): ari is string => !!ari)
				// remove duplicates (eg sharepoint, teams)
				.filter((ari, pos, arr) => {
					return arr.indexOf(ari) === pos;
				});

			return {
				range,
				titleMatchOnly: titleMatchValue,
				subtypes,
				integrations: integrationARIs,
				thirdPartyProducts,
			};
		} else {
			return {
				range,
				titleMatchOnly: titleMatchValue,
				subtypes,
				thirdPartyProducts,
			};
		}
	}

	return {
		titleMatchOnly: false,
		subtypes: [],
		integrations: [],
		// thirdPartyFilter
	};
};

export enum DateRangeError {
	FROM_AFTER_TO_ERROR = 'fromAfterToError',
	FROM_IN_FUTURE_ERROR = 'fromInFutureError',
	TO_IN_FUTURE_ERROR = 'toInFutureError',
}

export const getInvalidDateRange = (from: string, to: string): DateRangeError | null => {
	if (!from && !to) {
		return null;
	}
	const dateFrom: Date = new Date(`${from}${from ? 'T00:00:00' : ''}`);
	const dateTo: Date = new Date(`${to}${to ? 'T00:00:00' : ''}`);
	const now: Date = new Date();

	if (dateFrom > now) {
		return DateRangeError.FROM_IN_FUTURE_ERROR;
	}

	if (dateFrom > dateTo) {
		return DateRangeError.FROM_AFTER_TO_ERROR;
	}

	if (dateTo > now) {
		return DateRangeError.TO_IN_FUTURE_ERROR;
	}

	return null;
};

export const getProducts3PFromConfigForFilter = (filterId: FilterId): ProductKeys3P[] => {
	const productsForFilter: ProductKeys3P[] = [];
	ThirdPartyConfigs.forEach((_, productKey3P) => {
		if (ThirdPartyConfigs.get(productKey3P)?.filters?.includes(filterId)) {
			productsForFilter.push(productKey3P);
		}
	});
	return productsForFilter;
};

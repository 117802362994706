import { getInstance, type ICache } from '../common/cache';
import { getLocalStorageItem, setLocalStorageItem } from '../common/persist-helpers';

interface RefreshOnAccessArgs {
	/**
	 * Is the identifier for the name in the local storage.
	 */
	name: string;
}

/**
 * Cache implementation which always returns the persited response (if available).
 * But refreshes the response everytime its requested, so that next time onwards the data is up to date.
 *
 * Note - This will also return an expired result. This strategy should be used only in scenarios where:
 * 1. The data changes rarely.
 * 2. Its fine to serve the expired result to the user.
 */
export const refreshOnAccess = <T>(args: ICache & RefreshOnAccessArgs) => {
	const { name } = args;
	const cacheInstance = getInstance<T>(args);
	const persistedState = getLocalStorageItem(name);

	if (persistedState) {
		cacheInstance.deserialize(persistedState);
	}

	const get = (key: string, returnExpired?: boolean) =>
		cacheInstance.get(key, returnExpired ?? true); // fetch expired result by default.
	const set = async (key: string, result: Promise<T>) => {
		cacheInstance.set(key, result);
		await result;
		const serialized = await cacheInstance.serialize();
		setLocalStorageItem(name, serialized);
	};

	const refreshOnAccessDecorator = (
		key: string,
		supplier: () => Promise<T>,
		returnExpired?: boolean,
	) => {
		// cache hit
		const results = get(key, returnExpired);

		// cache refresh
		const apiPromise = supplier();
		// set the promise in the cache
		set(key, apiPromise);

		if (results) {
			// return the expired result
			return results;
		} else {
			// return the latest result
			return apiPromise;
		}
	};

	return {
		get,
		refreshOnAccessDecorator,
	};
};

import React from 'react';

import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { SearchResult } from '../search-result';

const commonStyles = xcss({
	backgroundColor: 'color.background.accent.gray.subtlest',
});

const skeletonTextStyles = xcss({
	borderRadius: 'border.radius.400',
});

const titleStyles = xcss({
	width: '280px',
	height: token('space.200'),
	marginBottom: 'space.050',
});

const iconStyles = xcss({
	width: 'size.400',
	height: 'size.400',
	borderRadius: 'border.radius.100',
});

const containerStyles = xcss({
	pointerEvents: 'none',
});

const descriptionContainerStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	rowGap: 'space.100',
});

const descriptionStyles = xcss({
	width: '100%',
	height: token('space.150'),
});

const metadataStyles = xcss({
	width: '200px',
	height: token('space.150'),
	marginBottom: 'space.050',
});

const metadataItemStyles = xcss({
	height: token('space.150'),
	borderRadius: 'border.radius.200',
});

const narrowMetadataItemStyles = xcss({
	width: '50px',
});

const wideMetadataItemStyles = xcss({
	width: '100px',
});

export const MetaDataItemSkeleton = () => {
	return <Box xcss={[commonStyles, metadataItemStyles, narrowMetadataItemStyles]} />;
};

export const MetaDataItemSkeletonWide = () => {
	return <Box xcss={[commonStyles, metadataItemStyles, wideMetadataItemStyles]} />;
};

export const SearchResultSkeleton = () => {
	return (
		<Box xcss={containerStyles}>
			<SearchResult
				description={
					<Box xcss={[descriptionContainerStyles]}>
						<Box xcss={[commonStyles, skeletonTextStyles, descriptionStyles]} />
						<Box xcss={[commonStyles, skeletonTextStyles, descriptionStyles]} />
					</Box>
				}
				icon={<Box xcss={[commonStyles, iconStyles]} />}
				title={<Box xcss={[commonStyles, skeletonTextStyles, titleStyles]} />}
				headerMetadata={<Box xcss={[commonStyles, skeletonTextStyles, metadataStyles]} />}
			/>
		</Box>
	);
};

import { fg } from '@atlaskit/platform-feature-flags';

// TODO QS-4805: We're slowly moving all filter typing to use this as a reference so we can be strongly typed!
// If you find a filterId masquerading as a `string` please consider migrating it
export type FilterId =
	| 'assignee'
	| 'content'
	| 'contributor'
	| 'lastUpdated'
	| 'project'
	| 'space'
	| 'verified'
	| 'onlyTitleMatch'
	| 'universalTypeFilter'
	| 'focusAreaType'
	| 'owner'
	| 'label';
// Keeping these separate for now

export const universalTypeFilterId = 'universalTypeFilter';
export const filterIdOrdering: string[] = [
	'lastUpdated',
	universalTypeFilterId,
	'contributor',
	'owner',
	'focusAreaType',
	'space',
	'assignee',
	'project',
	'content',
	'onlyTitleMatch',
	'verified',
	'label',
];

export const getFilterIdOrdering = (): string[] => {
	return filterIdOrdering.filter((filterId) => {
		if (filterId === 'label') {
			// eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix
			return fg('rovo_search_confluence_label_filter');
		} else {
			return true;
		}
	});
};

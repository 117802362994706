import isEqual from 'lodash/isEqual';
import {
	type Action,
	createActionsHook,
	createContainer,
	createHook,
	createStateHook,
	createStore,
} from 'react-sweet-state';

import type { SelectFilterOption } from '../../../common/ui/filters/select-filter';

export type SelectedQueryFilter = {
	filterId: string;
	keyword: string;
	displayText: string;
	option: SelectFilterOption;
};

type CurrentQueryFilter = {
	filterId: string;
	keyword: string;
};

export type QuickFindState = {
	quickFindOpen: boolean;
	quickFindQuery: string;
	isFeedbackModalOpen: boolean;
	fetchEntitiesRequestId?: string;
	fetchSpacesRequestId?: string;
	retryCount: number;
	updateRecentQueries?: () => void;
	selectedQueryFilters: SelectedQueryFilter[];
	currentQueryFilter?: CurrentQueryFilter;
};

export const initialState = {
	quickFindOpen: false,
	quickFindQuery: '',
	isFeedbackModalOpen: false,
	retryCount: 0,
	updateRecentQueries: undefined,
	selectedQueryFilters: [],
} satisfies QuickFindState;

const actions = {
	setQuickFindOpen:
		(quickFindOpen: QuickFindState['quickFindOpen']): Action<QuickFindState> =>
		({ setState }) => {
			setState({ quickFindOpen });
		},
	setQuickFindQuery:
		(quickFindQuery: QuickFindState['quickFindQuery']): Action<QuickFindState> =>
		({ setState }) => {
			setState({ quickFindQuery });
		},
	setIsFeedbackModalOpen:
		(feedbackOpen: QuickFindState['isFeedbackModalOpen']): Action<QuickFindState> =>
		({ setState }) => {
			setState({ isFeedbackModalOpen: feedbackOpen });
		},
	setRetryCount:
		(retryCount: QuickFindState['retryCount']): Action<QuickFindState> =>
		({ setState }) => {
			setState({ retryCount });
		},
	setFetchSpacesRequestId:
		(fetchSpacesRequestId: QuickFindState['fetchSpacesRequestId']): Action<QuickFindState> =>
		({ setState }) => {
			setState({ fetchSpacesRequestId });
		},
	setFetchEntitiesRequestId:
		(fetchEntitiesRequestId: QuickFindState['fetchEntitiesRequestId']): Action<QuickFindState> =>
		({ setState }) => {
			setState({ fetchEntitiesRequestId });
		},
	setUpdateRecentQueries:
		(updateRecentQueries: QuickFindState['updateRecentQueries']): Action<QuickFindState> =>
		({ setState }) => {
			setState({ updateRecentQueries });
		},
	addSelectedQueryFilter:
		(queryFilter: SelectedQueryFilter): Action<QuickFindState> =>
		({ setState, getState }) => {
			const { selectedQueryFilters } = getState();
			const duplicate = selectedQueryFilters.find((currentQueryFilter) =>
				isEqual(JSON.stringify(currentQueryFilter), JSON.stringify(queryFilter)),
			);

			if (!duplicate) {
				setState({ selectedQueryFilters: [...selectedQueryFilters, queryFilter] });
			}
		},
	deleteSelectedQueryFilter:
		(queryFilter: SelectedQueryFilter): Action<QuickFindState> =>
		({ setState, getState }) => {
			const { selectedQueryFilters } = getState();

			const newSelectedQueryFilters = selectedQueryFilters.filter(
				(currentQueryFilter) =>
					!isEqual(JSON.stringify(currentQueryFilter), JSON.stringify(queryFilter)),
			);

			setState({ selectedQueryFilters: newSelectedQueryFilters });
		},
	resetSelectedQueryFilters:
		(): Action<QuickFindState> =>
		({ setState }) => {
			setState({ selectedQueryFilters: [] });
		},
	setCurrentQueryFilter:
		(currentQueryFilter: QuickFindState['currentQueryFilter']): Action<QuickFindState> =>
		({ setState }) => {
			setState({ currentQueryFilter });
		},
};

type TActions = typeof actions;

const Store = createStore<QuickFindState, TActions>({
	initialState,
	actions,
	name: 'quick-find',
});

export const useQuickFindActions = createActionsHook(Store);

export const useQuickFindOpen = createStateHook(Store, {
	selector: (state) => state.quickFindOpen,
});

export const useQuickFindQuery = createStateHook(Store, {
	selector: (state) => state.quickFindQuery,
});

export const useIsFeedbackModalOpen = createHook(Store, {
	selector: (state) => state.isFeedbackModalOpen,
});

export const useRetryCount = createStateHook(Store, { selector: (state) => state.retryCount });

export const useFetchSpacesRequestId = createStateHook(Store, {
	selector: (state) => state.fetchSpacesRequestId,
});

export const useFetchEntitiesRequestId = createStateHook(Store, {
	selector: (state) => state.fetchEntitiesRequestId,
});

export const useUpdateRecentQueries = createStateHook(Store, {
	selector: (state) => state.updateRecentQueries,
});

export const useSelectedQueryFilters = createStateHook(Store, {
	selector: (state) => state.selectedQueryFilters,
});

export const useCurrentQueryFilter = createStateHook(Store, {
	selector: (state) => state.currentQueryFilter,
});

/**
 * This store is contained within a container as we may need to support multiple
 * instances of the Quick Find component in Nav3. This would be the case if we had
 * one in the navigation bar and another one in the search page.
 */
export const QuickFindStoreContainer = createContainer(Store);

import { type RequestServiceOptions, utils } from '@atlaskit/util-service-support';

import { generateHash } from '../../../utils/caching/common/key-generator';
import { refreshOnAccess } from '../../../utils/caching/persist-refresh';

const RELATIVE_URL = '/gateway/api/xpsearch-aggregator/configuration/v1';
const RELATIVE_URL_V2 = '/gateway/api/xpsearch-aggregator/api/v2/configuration';
const RELATIVE_3P_URL = '/gateway/api/third-party-configuration/connected-data-sources';
const RELATIVE_LOOM_AUTH_URL = '/gateway/api/loom-auth/api/internal/identity/user-status';

const { refreshOnAccessDecorator: refreshOnAccessDecoratorSearch } =
	refreshOnAccess<TSearchConfigAPIResponse>({ name: 'search-config' });

export const SEARCH_3P_CONFIG: string = 'search-3p-config';
const { refreshOnAccessDecorator: refreshOnAccessDecorator3pSearch } =
	refreshOnAccess<TSearch3pConfigAPIResponse>({
		name: SEARCH_3P_CONFIG,
		expiration: 1000 * 60 * 5,
	});
export const LOOM_USER_AUTH: string = 'loom-user-auth';
const { refreshOnAccessDecorator: refreshOnAccessDecoratorUserLoomAuth } =
	refreshOnAccess<LoomUserAuthResponse>({
		name: LOOM_USER_AUTH,
	});

const SITELESS_PRODUCTS = ['trello', 'bitbucket'];

export type TSearchConfigProps = {
	/**
	 * When passed this would be used as is to invoke the endpoint.
	 * This is useful for products like Trello where the API gateway is not on the host domain, but a completely different URL.
	 *
	 * When specified it takes precedence over `aggregatorHostName`.
	 */
	absoluteUrl?: string;

	/**
	 * When passed this would be used to invoke the endpoint. Example: https://product-fabric.atlassian.net
	 * This is used ONLY if absoluteUrl is not specified.
	 */
	hostName?: string;

	/**
	 * If passed then only the resources which are tied to the given cloudId are returned.
	 */
	cloudId?: string;

	/**
	 * Identifier of the user
	 */
	userId?: string | null;
};

export type TSearch3pConfigProps = {
	/**
	 * When passed this would be used as is to invoke the endpoint.
	 * This is useful for products like Trello where the API gateway is not on the host domain, but a completely different URL.
	 */
	absoluteUrl?: string;

	cloudId?: string;

	/**
	 * Identifier of the user
	 */
	userId?: string | null;

	/**
	 * If true, third party config fetch will be disabled
	 */
	disabled?: boolean;
};
export type LoomUserAuthProps = {
	/**
	 * When passed this would be used as is to invoke the endpoint.
	 * This is useful for products like Trello where the API gateway is not on the host domain, but a completely different URL.
	 */
	absoluteUrl?: string;

	cloudId?: string;

	/**
	 * Identifier of the user
	 */
	userId?: string | null;
};

export type TResource = {
	id: string; // this is actually an ARI , example - ari:cloud:confluence::site/690973c4-d02a-470f-b795-0af9b28f75c1
	baseUrl: string;
	siteName: string;
};

export type TSiteMetadata = {
	siteId: string;
	siteName: string;
	isNounsAvailable: boolean;
	isRovoEnabled: boolean;
};

export type TSiteMetadataV2 = {
	siteId: string;
	siteName: string;
	isNounsAvailable: boolean;
	isRovoEnabled: boolean;
	applicationMode: string;
};

export type T3pResource = {
	type: string;
	outboundAuthUrl: string;
	isUserOAuthed: boolean;
	providerId: string;
	workspaceName?: string;
};

export type TSearchConfigAPIResponse = {
	resources: TResource[];
	intercomHmac: string;
	siteMetadata: TSiteMetadata[];
};

export type TSearchConfigAPIResponseV2 = {
	resources: TResource[];
	intercomHmac: string;
	siteMetadata: TSiteMetadata[];
};

export type LoomUserAuthResponse = {
	status?: string;
};

export type TSearch3pConfigAPIResponse = T3pResource[];

// https://developer.atlassian.com/platform/cross-product-search/rest/api-group-other-operations/#api-configuration-v1-get
export const getSearchConfig = async ({
	absoluteUrl = '',
	hostName,
	cloudId,
	userId,
}: TSearchConfigProps): Promise<TSearchConfigAPIResponse> => {
	const url = absoluteUrl || `${hostName || ''}${RELATIVE_URL}`;

	const options: RequestServiceOptions = {
		requestInit: {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		},
	};

	const key = generateHash(`1p|${userId}|${cloudId}`);
	const supplier = () =>
		utils
			.requestService<TSearchConfigAPIResponse>({ url }, options)
			.then((data: TSearchConfigAPIResponse) => {
				const resources = data.resources.filter((resource) =>
					cloudId
						? resource.id.includes(cloudId) ||
							SITELESS_PRODUCTS.find((product) => resource.id.includes(product)) // Allow access to products that are not associated with a site
						: true,
				);

				return {
					resources: resources,
					intercomHmac: data.intercomHmac || '',
					siteMetadata: data.siteMetadata || [],
				};
			});

	return refreshOnAccessDecoratorSearch(key.toString(), supplier);
};

// https://developer.atlassian.com/platform/cross-product-search/rest/api-group-other-operations/#api-api-v2-configuration-cloudid-get
export const getSearchConfigV2 = async ({
	absoluteUrl = '',
	hostName,
	cloudId,
	userId,
}: TSearchConfigProps): Promise<TSearchConfigAPIResponseV2> => {
	const url = absoluteUrl || `${hostName || ''}${RELATIVE_URL_V2}/${cloudId}`;

	const options: RequestServiceOptions = {
		requestInit: {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		},
	};

	const key = generateHash(`1p|${userId}|${cloudId}|v2`);
	const supplier = () =>
		utils
			.requestService<TSearchConfigAPIResponse>({ url }, options)
			.then((data: TSearchConfigAPIResponse) => {
				return {
					resources: data.resources,
					intercomHmac: data.intercomHmac || '',
					siteMetadata: data.siteMetadata || [],
				};
			});

	return refreshOnAccessDecoratorSearch(key.toString(), supplier);
};

/**
 * Sample response:
 * [
      {
        "type": "ai-3p-connector:google-workspace",
        "location": "ari:third-party:google::site/DUMMY-0c418054-69d8-4a6c-bec6-8ef053070a36"
      }
    ]
 */

export const get3pSearchConfig = async ({
	absoluteUrl = '',
	cloudId,
	userId,
}: TSearch3pConfigProps): Promise<TSearch3pConfigAPIResponse> => {
	const baseUrl = absoluteUrl || RELATIVE_3P_URL;
	const url = `${baseUrl}/${cloudId}`;

	const options: RequestServiceOptions = {
		requestInit: {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		},
	};
	const key = generate3pHash(userId || '', cloudId || '');

	const supplier = () => utils.requestService<TSearch3pConfigAPIResponse>({ url }, options);

	return refreshOnAccessDecorator3pSearch(key.toString(), supplier, false);
};

export const generate3pHash = (userId: string, cloudId: string) =>
	generateHash(`3p|${userId}|${cloudId}`);

/**
 * Sample response:
 *
      {
        "status": "mastered",
      }
 */

export const getUserLoomAuthStatus = async ({
	cloudId,
	userId,
}: LoomUserAuthProps): Promise<LoomUserAuthResponse> => {
	const url = RELATIVE_LOOM_AUTH_URL;
	const options: RequestServiceOptions = {
		requestInit: {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		},
	};
	const key = generateLoomAuthHash(userId || '', cloudId || '');

	const supplier = () => utils.requestService<LoomUserAuthResponse>({ url }, options);

	return refreshOnAccessDecoratorUserLoomAuth(key.toString(), supplier, false);
};

export const generateLoomAuthHash = (userId: string, cloudId: string) =>
	generateHash(`loom|${userId}|${cloudId}`);

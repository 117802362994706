import { type EntityType, type Maybe, type ProductType } from '../../../../common/types';
import { type AggRequest, type AggResponse, fetchAgg } from '../../../../common/utils/fetch-agg';
import { generateHashFromVariables } from '../../../../utils/caching/common/key-generator';
import { inMemoryCache } from '../../../../utils/caching/in-memory';

const operationName = 'SearchEntitiesQuery';
const getSearchEntitiesQuery = ({ includeSpaceKey }: { includeSpaceKey?: boolean }) => {
	const SearchConfluenceSpace = `
    ... on SearchConfluenceSpace {
      key
    }
  `;

	return `
  query ${operationName}($experience: String!, $query: String!, $first: Int, $filters: SearchFilterInput!) {
    search {
      results: search(
        experience: $experience
        query: $query
        first: $first
        filters: $filters
      ) {
        edges {
          node {
            id
            title
            type
            url
            iconUrl
            ${includeSpaceKey ? SearchConfluenceSpace : ``}
          }
        }
      }
    }
  }
  `;
};

export type SearchEntitiesNode = {
	id: string;
	title: string;
	type: EntityType;
	url: string;
	iconUrl: Maybe<string>;
	key?: Maybe<string>;
};

export type SearchEntitiesProductEntityType = `ati:cloud:${ProductType}:${EntityType}`;
export type SearchEntitiesProductARI = `ari:cloud:${ProductType}::${string}`;

export type SearchEntitiesQueryVariables = {
	query: string;
	experience?: string;
	first?: number;
	filters: {
		entities: SearchEntitiesProductEntityType[];
		locations: SearchEntitiesProductARI[];
	};
};

export type SearchEntitiesQueryData = {
	search: Maybe<{
		results: {
			edges: Array<{
				node: SearchEntitiesNode;
			}>;
		};
	}>;
};

export const cache = inMemoryCache<AggResponse<SearchEntitiesQueryData>>();

// Expire the cache if there are errors or edges are empty
const expireWhen = (result: AggResponse<SearchEntitiesQueryData>) => {
	const { data, errors } = result;
	if (!data || (errors && errors.length > 0)) {
		return true;
	}
	return data.search?.results.edges.length === 0;
};

export type SearchEntitiesQueryProps = Pick<
	AggRequest<SearchEntitiesQueryVariables>,
	'aggAbsoluteUrl' | 'variables'
>;

export const searchEntitiesQuery = ({
	variables,
	aggAbsoluteUrl,
	includeSpaceKey,
}: SearchEntitiesQueryProps & {
	includeSpaceKey?: boolean;
}) =>
	cache.inMemoryDecorator(
		generateHashFromVariables(variables),
		() =>
			fetchAgg<SearchEntitiesQueryVariables, SearchEntitiesQueryData>({
				variables: {
					first: 20,
					experience: '',
					...variables,
				},
				aggAbsoluteUrl,
				graphQuery: getSearchEntitiesQuery({ includeSpaceKey }),
				operationName,
			}),
		expireWhen,
	);

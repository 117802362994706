import React from 'react';

import { FormattedMessage, useIntl } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import Heading from '@atlaskit/heading';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import { Grid, Inline, xcss } from '@atlaskit/primitives';
import Pressable from '@atlaskit/primitives/pressable';
import Text from '@atlaskit/primitives/text';

import { ProductKeys, useProductConfigs } from '../../../constants/products';

import { messages } from './messages';

export interface OAuthMessageProps {
	product: ProductKeys;
	testId?: string;
	onAuthorize: (e: React.MouseEvent<HTMLElement>) => void;
	onDismiss: (e: React.MouseEvent<HTMLElement>) => void;
	isOnSearchDialog?: boolean;
}

const containerBaseStyles = xcss({
	wordBreak: 'break-word',
	borderRadius: 'border.radius',
	backgroundColor: 'color.background.neutral',
});

const searchResultsContainerStyles = xcss({
	paddingLeft: 'space.150',
	paddingRight: 'space.150',
	paddingTop: 'space.150',
	paddingBottom: 'space.150',
});

const searchDialogContainerStyles = xcss({
	paddingLeft: 'space.100',
	paddingRight: 'space.100',
	paddingTop: 'space.100',
	paddingBottom: 'space.100',
});

const connectPressableStyles = xcss({
	paddingLeft: 'space.100',
	paddingRight: 'space.100',
	paddingInline: 'space.150',
	font: 'font.body',
	fontWeight: 'font.weight.medium',
	color: 'color.link',

	':hover': {
		textDecoration: 'underline',
		color: 'color.link.pressed',
	},
	':active': {
		textDecoration: 'underline',
		color: 'color.link.pressed',
	},
	whiteSpace: 'nowrap', // so that "Connect" doesn't get broken up needlessly
});

const actionsContainer = xcss({
	flexBasis: 'fit-content',
});

export const OAuthMessage = (props: OAuthMessageProps) => {
	const { product, onAuthorize, onDismiss, testId, isOnSearchDialog } = props;
	const { formatMessage } = useIntl();
	const products = useProductConfigs();
	const productString = product === 'sharepoint' ? 'SharePoint' : products[product].displayName;
	const Icon = products[product].Icon;

	const connectThirdPartyProductTitle = (
		<FormattedMessage
			{...(product === 'figma'
				? messages.connectThirdPartyProductTitleAlternate
				: messages.connectThirdPartyProductTitle)}
			values={{ product: productString }}
		/>
	);

	return (
		<Grid
			alignItems="center"
			templateAreas={['icon text cta']}
			templateColumns="min-content 1fr auto"
			columnGap="space.150"
			testId={testId}
			xcss={[
				containerBaseStyles,
				isOnSearchDialog ? searchDialogContainerStyles : searchResultsContainerStyles,
			]}
		>
			<Icon size={isOnSearchDialog ? 'small' : undefined} />
			<Inline space={isOnSearchDialog ? 'space.025' : 'space.050'} alignBlock="center" shouldWrap>
				{isOnSearchDialog ? (
					<Text size="medium" as="strong">
						{connectThirdPartyProductTitle}
					</Text>
				) : (
					<Heading size="small">{connectThirdPartyProductTitle}</Heading>
				)}
				<Text size="medium" color={isOnSearchDialog ? 'color.text.subtle' : undefined}>
					<FormattedMessage {...messages.connectThirdPartyProductDescription} />
				</Text>
			</Inline>
			<Inline
				testId={testId && `${testId}--actions`}
				space="space.150"
				rowSpace="space.0"
				alignBlock="center"
				xcss={actionsContainer}
			>
				<Pressable
					onClick={onAuthorize}
					backgroundColor="color.background.neutral.subtle"
					xcss={connectPressableStyles}
				>
					<Text weight="medium" size="medium" color="color.link">
						<FormattedMessage {...messages.connect} />
					</Text>
				</Pressable>
				<IconButton
					icon={(iconProps) => (
						<CrossIcon {...iconProps} label="" LEGACY_size="medium" color="currentColor" />
					)}
					onClick={onDismiss}
					label={formatMessage(messages.dismiss)}
					appearance={'subtle'}
					spacing={'compact'}
				/>
			</Inline>
		</Grid>
	);
};
